/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onOrderByRestaurantId = /* GraphQL */ `subscription OnOrderByRestaurantId($orderRestaurantId: ID!) {
  onOrderByRestaurantId(orderRestaurantId: $orderRestaurantId) {
    id
    userID
    Restaurant {
      id
      adminSub
      listRanking
      name
      image {
        logoKey
        teaserKey
        __typename
      }
      minDeliveryTime
      maxDeliveryTime
      rating
      address
      lat
      lng
      Dishes {
        items {
          id
          name
          image
          description
          shortDescription
          price
          restaurantID
          soldOut
          dishType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dishCategoryId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Baskets {
        items {
          id
          userID
          restaurantID
          note
          deliveryMethod
          deliveryProvider
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      categories {
        items {
          id
          name
          restaurantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      imageStorageKey
      status
      invoices {
        items {
          id
          amount
          customerFee
          deliveryFee
          restaurantFee
          customerID
          customerEmail
          restaurantID
          paymentIntent
          paymentIntentID
          pdfInvoice
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      phone
      email
      emailPrinter
      autoPickUp
      customData
      billingDetails {
        address {
          city
          country
          line1
          line2
          postal_code
          state
          __typename
        }
        email
        name
        phone
        __typename
      }
      printerGCMToken
      businessHours {
        items {
          id
          restaurantID
          day
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      loggingEnabled
      deliveryProvider
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    total
    totalPaid
    voucherCode
    voucherAmountOff
    voucherType
    voucherPercentOff
    status
    OrderDishes {
      items {
        id
        quantity
        Dish {
          id
          name
          image
          description
          shortDescription
          price
          restaurantID
          soldOut
          dishType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dishCategoryId
          __typename
        }
        orderID
        selectedDishOptions {
          nextToken
          startedAt
          __typename
        }
        selectedDishOptionsJSON
        note
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        orderDishDishId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Driver {
      id
      name
      sub
      email
      password
      lat
      lng
      transportationMode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    read
    printed
    orderNumber
    note
    ready
    createdAt
    orderRestaurantId
    deliveryMethod
    deliveryProvider
    updatedAt
    _version
    _deleted
    _lastChangedAt
    orderDriverId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnOrderByRestaurantIdSubscriptionVariables,
  APITypes.OnOrderByRestaurantIdSubscription
>;
export const onCreateInvoice = /* GraphQL */ `subscription OnCreateInvoice($filter: ModelSubscriptionInvoiceFilterInput) {
  onCreateInvoice(filter: $filter) {
    id
    amount
    customerFee
    deliveryFee
    restaurantFee
    customerID
    customerEmail
    restaurantID
    paymentIntent
    paymentIntentID
    details {
      name
      description
      price
      options {
        title
        items {
          title
          price
          __typename
        }
        __typename
      }
      __typename
    }
    pdfInvoice
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateInvoiceSubscriptionVariables,
  APITypes.OnCreateInvoiceSubscription
>;
export const onUpdateInvoice = /* GraphQL */ `subscription OnUpdateInvoice($filter: ModelSubscriptionInvoiceFilterInput) {
  onUpdateInvoice(filter: $filter) {
    id
    amount
    customerFee
    deliveryFee
    restaurantFee
    customerID
    customerEmail
    restaurantID
    paymentIntent
    paymentIntentID
    details {
      name
      description
      price
      options {
        title
        items {
          title
          price
          __typename
        }
        __typename
      }
      __typename
    }
    pdfInvoice
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateInvoiceSubscriptionVariables,
  APITypes.OnUpdateInvoiceSubscription
>;
export const onDeleteInvoice = /* GraphQL */ `subscription OnDeleteInvoice($filter: ModelSubscriptionInvoiceFilterInput) {
  onDeleteInvoice(filter: $filter) {
    id
    amount
    customerFee
    deliveryFee
    restaurantFee
    customerID
    customerEmail
    restaurantID
    paymentIntent
    paymentIntentID
    details {
      name
      description
      price
      options {
        title
        items {
          title
          price
          __typename
        }
        __typename
      }
      __typename
    }
    pdfInvoice
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteInvoiceSubscriptionVariables,
  APITypes.OnDeleteInvoiceSubscription
>;
export const onCreateDishCategory = /* GraphQL */ `subscription OnCreateDishCategory(
  $filter: ModelSubscriptionDishCategoryFilterInput
) {
  onCreateDishCategory(filter: $filter) {
    id
    name
    description
    imageKey
    restaurantID
    order
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateDishCategorySubscriptionVariables,
  APITypes.OnCreateDishCategorySubscription
>;
export const onUpdateDishCategory = /* GraphQL */ `subscription OnUpdateDishCategory(
  $filter: ModelSubscriptionDishCategoryFilterInput
) {
  onUpdateDishCategory(filter: $filter) {
    id
    name
    description
    imageKey
    restaurantID
    order
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateDishCategorySubscriptionVariables,
  APITypes.OnUpdateDishCategorySubscription
>;
export const onDeleteDishCategory = /* GraphQL */ `subscription OnDeleteDishCategory(
  $filter: ModelSubscriptionDishCategoryFilterInput
) {
  onDeleteDishCategory(filter: $filter) {
    id
    name
    description
    imageKey
    restaurantID
    order
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteDishCategorySubscriptionVariables,
  APITypes.OnDeleteDishCategorySubscription
>;
export const onCreateDishOptionItem = /* GraphQL */ `subscription OnCreateDishOptionItem(
  $filter: ModelSubscriptionDishOptionItemFilterInput
) {
  onCreateDishOptionItem(filter: $filter) {
    id
    title
    description
    price
    dishOptionID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateDishOptionItemSubscriptionVariables,
  APITypes.OnCreateDishOptionItemSubscription
>;
export const onUpdateDishOptionItem = /* GraphQL */ `subscription OnUpdateDishOptionItem(
  $filter: ModelSubscriptionDishOptionItemFilterInput
) {
  onUpdateDishOptionItem(filter: $filter) {
    id
    title
    description
    price
    dishOptionID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateDishOptionItemSubscriptionVariables,
  APITypes.OnUpdateDishOptionItemSubscription
>;
export const onDeleteDishOptionItem = /* GraphQL */ `subscription OnDeleteDishOptionItem(
  $filter: ModelSubscriptionDishOptionItemFilterInput
) {
  onDeleteDishOptionItem(filter: $filter) {
    id
    title
    description
    price
    dishOptionID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteDishOptionItemSubscriptionVariables,
  APITypes.OnDeleteDishOptionItemSubscription
>;
export const onCreateDishOption = /* GraphQL */ `subscription OnCreateDishOption(
  $filter: ModelSubscriptionDishOptionFilterInput
) {
  onCreateDishOption(filter: $filter) {
    id
    title
    description
    multipleSelection
    obligatory
    items {
      items {
        id
        title
        description
        price
        dishOptionID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    dishIDs
    restaurantID
    basketDishID
    orderDishID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateDishOptionSubscriptionVariables,
  APITypes.OnCreateDishOptionSubscription
>;
export const onUpdateDishOption = /* GraphQL */ `subscription OnUpdateDishOption(
  $filter: ModelSubscriptionDishOptionFilterInput
) {
  onUpdateDishOption(filter: $filter) {
    id
    title
    description
    multipleSelection
    obligatory
    items {
      items {
        id
        title
        description
        price
        dishOptionID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    dishIDs
    restaurantID
    basketDishID
    orderDishID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateDishOptionSubscriptionVariables,
  APITypes.OnUpdateDishOptionSubscription
>;
export const onDeleteDishOption = /* GraphQL */ `subscription OnDeleteDishOption(
  $filter: ModelSubscriptionDishOptionFilterInput
) {
  onDeleteDishOption(filter: $filter) {
    id
    title
    description
    multipleSelection
    obligatory
    items {
      items {
        id
        title
        description
        price
        dishOptionID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    dishIDs
    restaurantID
    basketDishID
    orderDishID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteDishOptionSubscriptionVariables,
  APITypes.OnDeleteDishOptionSubscription
>;
export const onCreateDish = /* GraphQL */ `subscription OnCreateDish($filter: ModelSubscriptionDishFilterInput) {
  onCreateDish(filter: $filter) {
    id
    name
    image
    description
    shortDescription
    price
    restaurantID
    soldOut
    category {
      id
      name
      description
      imageKey
      restaurantID
      order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    allergens {
      id
      name
      __typename
    }
    dishType
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    dishCategoryId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateDishSubscriptionVariables,
  APITypes.OnCreateDishSubscription
>;
export const onUpdateDish = /* GraphQL */ `subscription OnUpdateDish($filter: ModelSubscriptionDishFilterInput) {
  onUpdateDish(filter: $filter) {
    id
    name
    image
    description
    shortDescription
    price
    restaurantID
    soldOut
    category {
      id
      name
      description
      imageKey
      restaurantID
      order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    allergens {
      id
      name
      __typename
    }
    dishType
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    dishCategoryId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateDishSubscriptionVariables,
  APITypes.OnUpdateDishSubscription
>;
export const onDeleteDish = /* GraphQL */ `subscription OnDeleteDish($filter: ModelSubscriptionDishFilterInput) {
  onDeleteDish(filter: $filter) {
    id
    name
    image
    description
    shortDescription
    price
    restaurantID
    soldOut
    category {
      id
      name
      description
      imageKey
      restaurantID
      order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    allergens {
      id
      name
      __typename
    }
    dishType
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    dishCategoryId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteDishSubscriptionVariables,
  APITypes.OnDeleteDishSubscription
>;
export const onCreateBusinessHourRestaurant = /* GraphQL */ `subscription OnCreateBusinessHourRestaurant(
  $filter: ModelSubscriptionBusinessHourRestaurantFilterInput
) {
  onCreateBusinessHourRestaurant(filter: $filter) {
    id
    restaurantID
    periods {
      label
      start
      end
      __typename
    }
    day
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBusinessHourRestaurantSubscriptionVariables,
  APITypes.OnCreateBusinessHourRestaurantSubscription
>;
export const onUpdateBusinessHourRestaurant = /* GraphQL */ `subscription OnUpdateBusinessHourRestaurant(
  $filter: ModelSubscriptionBusinessHourRestaurantFilterInput
) {
  onUpdateBusinessHourRestaurant(filter: $filter) {
    id
    restaurantID
    periods {
      label
      start
      end
      __typename
    }
    day
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBusinessHourRestaurantSubscriptionVariables,
  APITypes.OnUpdateBusinessHourRestaurantSubscription
>;
export const onDeleteBusinessHourRestaurant = /* GraphQL */ `subscription OnDeleteBusinessHourRestaurant(
  $filter: ModelSubscriptionBusinessHourRestaurantFilterInput
) {
  onDeleteBusinessHourRestaurant(filter: $filter) {
    id
    restaurantID
    periods {
      label
      start
      end
      __typename
    }
    day
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBusinessHourRestaurantSubscriptionVariables,
  APITypes.OnDeleteBusinessHourRestaurantSubscription
>;
export const onCreateBusinessHourTasty = /* GraphQL */ `subscription OnCreateBusinessHourTasty(
  $filter: ModelSubscriptionBusinessHourTastyFilterInput
) {
  onCreateBusinessHourTasty(filter: $filter) {
    id
    day
    appConfigID
    periods {
      label
      start
      end
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBusinessHourTastySubscriptionVariables,
  APITypes.OnCreateBusinessHourTastySubscription
>;
export const onUpdateBusinessHourTasty = /* GraphQL */ `subscription OnUpdateBusinessHourTasty(
  $filter: ModelSubscriptionBusinessHourTastyFilterInput
) {
  onUpdateBusinessHourTasty(filter: $filter) {
    id
    day
    appConfigID
    periods {
      label
      start
      end
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBusinessHourTastySubscriptionVariables,
  APITypes.OnUpdateBusinessHourTastySubscription
>;
export const onDeleteBusinessHourTasty = /* GraphQL */ `subscription OnDeleteBusinessHourTasty(
  $filter: ModelSubscriptionBusinessHourTastyFilterInput
) {
  onDeleteBusinessHourTasty(filter: $filter) {
    id
    day
    appConfigID
    periods {
      label
      start
      end
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBusinessHourTastySubscriptionVariables,
  APITypes.OnDeleteBusinessHourTastySubscription
>;
export const onCreateAppConfig = /* GraphQL */ `subscription OnCreateAppConfig($filter: ModelSubscriptionAppConfigFilterInput) {
  onCreateAppConfig(filter: $filter) {
    id
    customerFee
    deliveryFee
    restaurantFee
    minimumOrderValue
    openingHours
    businessHours {
      items {
        id
        day
        appConfigID
        periods {
          label
          start
          end
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    maintenance {
      active
      title
      description
      type
      __typename
    }
    taxRate
    customData
    banner {
      visible
      imageURL
      linkURL
      __typename
    }
    orderStop {
      active
      title
      description
      __typename
    }
    voucherEnabled
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAppConfigSubscriptionVariables,
  APITypes.OnCreateAppConfigSubscription
>;
export const onUpdateAppConfig = /* GraphQL */ `subscription OnUpdateAppConfig($filter: ModelSubscriptionAppConfigFilterInput) {
  onUpdateAppConfig(filter: $filter) {
    id
    customerFee
    deliveryFee
    restaurantFee
    minimumOrderValue
    openingHours
    businessHours {
      items {
        id
        day
        appConfigID
        periods {
          label
          start
          end
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    maintenance {
      active
      title
      description
      type
      __typename
    }
    taxRate
    customData
    banner {
      visible
      imageURL
      linkURL
      __typename
    }
    orderStop {
      active
      title
      description
      __typename
    }
    voucherEnabled
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAppConfigSubscriptionVariables,
  APITypes.OnUpdateAppConfigSubscription
>;
export const onDeleteAppConfig = /* GraphQL */ `subscription OnDeleteAppConfig($filter: ModelSubscriptionAppConfigFilterInput) {
  onDeleteAppConfig(filter: $filter) {
    id
    customerFee
    deliveryFee
    restaurantFee
    minimumOrderValue
    openingHours
    businessHours {
      items {
        id
        day
        appConfigID
        periods {
          label
          start
          end
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    maintenance {
      active
      title
      description
      type
      __typename
    }
    taxRate
    customData
    banner {
      visible
      imageURL
      linkURL
      __typename
    }
    orderStop {
      active
      title
      description
      __typename
    }
    voucherEnabled
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAppConfigSubscriptionVariables,
  APITypes.OnDeleteAppConfigSubscription
>;
export const onCreateDriver = /* GraphQL */ `subscription OnCreateDriver($filter: ModelSubscriptionDriverFilterInput) {
  onCreateDriver(filter: $filter) {
    id
    name
    sub
    email
    password
    lat
    lng
    transportationMode
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateDriverSubscriptionVariables,
  APITypes.OnCreateDriverSubscription
>;
export const onUpdateDriver = /* GraphQL */ `subscription OnUpdateDriver($filter: ModelSubscriptionDriverFilterInput) {
  onUpdateDriver(filter: $filter) {
    id
    name
    sub
    email
    password
    lat
    lng
    transportationMode
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateDriverSubscriptionVariables,
  APITypes.OnUpdateDriverSubscription
>;
export const onDeleteDriver = /* GraphQL */ `subscription OnDeleteDriver($filter: ModelSubscriptionDriverFilterInput) {
  onDeleteDriver(filter: $filter) {
    id
    name
    sub
    email
    password
    lat
    lng
    transportationMode
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteDriverSubscriptionVariables,
  APITypes.OnDeleteDriverSubscription
>;
export const onCreateUser = /* GraphQL */ `subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
  onCreateUser(filter: $filter) {
    id
    sub
    name
    address
    addressData {
      street
      city
      country
      district
      formattedAddress
      lat
      lng
      __typename
    }
    email
    phone
    lat
    lng
    Orders {
      items {
        id
        userID
        Restaurant {
          id
          adminSub
          listRanking
          name
          minDeliveryTime
          maxDeliveryTime
          rating
          address
          lat
          lng
          imageStorageKey
          status
          phone
          email
          emailPrinter
          autoPickUp
          customData
          printerGCMToken
          loggingEnabled
          deliveryProvider
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        total
        totalPaid
        voucherCode
        voucherAmountOff
        voucherType
        voucherPercentOff
        status
        OrderDishes {
          nextToken
          startedAt
          __typename
        }
        Driver {
          id
          name
          sub
          email
          password
          lat
          lng
          transportationMode
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        read
        printed
        orderNumber
        note
        ready
        createdAt
        orderRestaurantId
        deliveryMethod
        deliveryProvider
        updatedAt
        _version
        _deleted
        _lastChangedAt
        orderDriverId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Baskets {
      items {
        id
        BasketDishes {
          nextToken
          startedAt
          __typename
        }
        userID
        restaurantID
        note
        deliveryMethod
        deliveryProvider
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    deliveryAddressID
    DeliveryAddresses {
      items {
        id
        name
        addressData {
          street
          city
          country
          district
          formattedAddress
          lat
          lng
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    invoices {
      items {
        id
        amount
        customerFee
        deliveryFee
        restaurantFee
        customerID
        customerEmail
        restaurantID
        paymentIntent
        paymentIntentID
        details {
          name
          description
          price
          __typename
        }
        pdfInvoice
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    newsletter
    company
    birthday
    stripeCustomerID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserSubscriptionVariables,
  APITypes.OnCreateUserSubscription
>;
export const onUpdateUser = /* GraphQL */ `subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
  onUpdateUser(filter: $filter) {
    id
    sub
    name
    address
    addressData {
      street
      city
      country
      district
      formattedAddress
      lat
      lng
      __typename
    }
    email
    phone
    lat
    lng
    Orders {
      items {
        id
        userID
        Restaurant {
          id
          adminSub
          listRanking
          name
          minDeliveryTime
          maxDeliveryTime
          rating
          address
          lat
          lng
          imageStorageKey
          status
          phone
          email
          emailPrinter
          autoPickUp
          customData
          printerGCMToken
          loggingEnabled
          deliveryProvider
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        total
        totalPaid
        voucherCode
        voucherAmountOff
        voucherType
        voucherPercentOff
        status
        OrderDishes {
          nextToken
          startedAt
          __typename
        }
        Driver {
          id
          name
          sub
          email
          password
          lat
          lng
          transportationMode
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        read
        printed
        orderNumber
        note
        ready
        createdAt
        orderRestaurantId
        deliveryMethod
        deliveryProvider
        updatedAt
        _version
        _deleted
        _lastChangedAt
        orderDriverId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Baskets {
      items {
        id
        BasketDishes {
          nextToken
          startedAt
          __typename
        }
        userID
        restaurantID
        note
        deliveryMethod
        deliveryProvider
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    deliveryAddressID
    DeliveryAddresses {
      items {
        id
        name
        addressData {
          street
          city
          country
          district
          formattedAddress
          lat
          lng
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    invoices {
      items {
        id
        amount
        customerFee
        deliveryFee
        restaurantFee
        customerID
        customerEmail
        restaurantID
        paymentIntent
        paymentIntentID
        details {
          name
          description
          price
          __typename
        }
        pdfInvoice
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    newsletter
    company
    birthday
    stripeCustomerID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserSubscriptionVariables,
  APITypes.OnUpdateUserSubscription
>;
export const onDeleteUser = /* GraphQL */ `subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
  onDeleteUser(filter: $filter) {
    id
    sub
    name
    address
    addressData {
      street
      city
      country
      district
      formattedAddress
      lat
      lng
      __typename
    }
    email
    phone
    lat
    lng
    Orders {
      items {
        id
        userID
        Restaurant {
          id
          adminSub
          listRanking
          name
          minDeliveryTime
          maxDeliveryTime
          rating
          address
          lat
          lng
          imageStorageKey
          status
          phone
          email
          emailPrinter
          autoPickUp
          customData
          printerGCMToken
          loggingEnabled
          deliveryProvider
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        total
        totalPaid
        voucherCode
        voucherAmountOff
        voucherType
        voucherPercentOff
        status
        OrderDishes {
          nextToken
          startedAt
          __typename
        }
        Driver {
          id
          name
          sub
          email
          password
          lat
          lng
          transportationMode
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        read
        printed
        orderNumber
        note
        ready
        createdAt
        orderRestaurantId
        deliveryMethod
        deliveryProvider
        updatedAt
        _version
        _deleted
        _lastChangedAt
        orderDriverId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Baskets {
      items {
        id
        BasketDishes {
          nextToken
          startedAt
          __typename
        }
        userID
        restaurantID
        note
        deliveryMethod
        deliveryProvider
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    deliveryAddressID
    DeliveryAddresses {
      items {
        id
        name
        addressData {
          street
          city
          country
          district
          formattedAddress
          lat
          lng
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    invoices {
      items {
        id
        amount
        customerFee
        deliveryFee
        restaurantFee
        customerID
        customerEmail
        restaurantID
        paymentIntent
        paymentIntentID
        details {
          name
          description
          price
          __typename
        }
        pdfInvoice
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    newsletter
    company
    birthday
    stripeCustomerID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserSubscriptionVariables,
  APITypes.OnDeleteUserSubscription
>;
export const onCreateBasketDish = /* GraphQL */ `subscription OnCreateBasketDish(
  $filter: ModelSubscriptionBasketDishFilterInput
) {
  onCreateBasketDish(filter: $filter) {
    id
    quantity
    Dish {
      id
      name
      image
      description
      shortDescription
      price
      restaurantID
      soldOut
      category {
        id
        name
        description
        imageKey
        restaurantID
        order
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      allergens {
        id
        name
        __typename
      }
      dishType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dishCategoryId
      __typename
    }
    basketID
    selectedDishOptions {
      items {
        id
        title
        description
        multipleSelection
        obligatory
        items {
          nextToken
          startedAt
          __typename
        }
        dishIDs
        restaurantID
        basketDishID
        orderDishID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    selectedDishOptionsJSON
    note
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    basketDishDishId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBasketDishSubscriptionVariables,
  APITypes.OnCreateBasketDishSubscription
>;
export const onUpdateBasketDish = /* GraphQL */ `subscription OnUpdateBasketDish(
  $filter: ModelSubscriptionBasketDishFilterInput
) {
  onUpdateBasketDish(filter: $filter) {
    id
    quantity
    Dish {
      id
      name
      image
      description
      shortDescription
      price
      restaurantID
      soldOut
      category {
        id
        name
        description
        imageKey
        restaurantID
        order
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      allergens {
        id
        name
        __typename
      }
      dishType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dishCategoryId
      __typename
    }
    basketID
    selectedDishOptions {
      items {
        id
        title
        description
        multipleSelection
        obligatory
        items {
          nextToken
          startedAt
          __typename
        }
        dishIDs
        restaurantID
        basketDishID
        orderDishID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    selectedDishOptionsJSON
    note
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    basketDishDishId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBasketDishSubscriptionVariables,
  APITypes.OnUpdateBasketDishSubscription
>;
export const onDeleteBasketDish = /* GraphQL */ `subscription OnDeleteBasketDish(
  $filter: ModelSubscriptionBasketDishFilterInput
) {
  onDeleteBasketDish(filter: $filter) {
    id
    quantity
    Dish {
      id
      name
      image
      description
      shortDescription
      price
      restaurantID
      soldOut
      category {
        id
        name
        description
        imageKey
        restaurantID
        order
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      allergens {
        id
        name
        __typename
      }
      dishType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dishCategoryId
      __typename
    }
    basketID
    selectedDishOptions {
      items {
        id
        title
        description
        multipleSelection
        obligatory
        items {
          nextToken
          startedAt
          __typename
        }
        dishIDs
        restaurantID
        basketDishID
        orderDishID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    selectedDishOptionsJSON
    note
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    basketDishDishId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBasketDishSubscriptionVariables,
  APITypes.OnDeleteBasketDishSubscription
>;
export const onCreateBasket = /* GraphQL */ `subscription OnCreateBasket($filter: ModelSubscriptionBasketFilterInput) {
  onCreateBasket(filter: $filter) {
    id
    BasketDishes {
      items {
        id
        quantity
        Dish {
          id
          name
          image
          description
          shortDescription
          price
          restaurantID
          soldOut
          dishType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dishCategoryId
          __typename
        }
        basketID
        selectedDishOptions {
          nextToken
          startedAt
          __typename
        }
        selectedDishOptionsJSON
        note
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        basketDishDishId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    userID
    restaurantID
    note
    deliveryMethod
    deliveryProvider
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBasketSubscriptionVariables,
  APITypes.OnCreateBasketSubscription
>;
export const onUpdateBasket = /* GraphQL */ `subscription OnUpdateBasket($filter: ModelSubscriptionBasketFilterInput) {
  onUpdateBasket(filter: $filter) {
    id
    BasketDishes {
      items {
        id
        quantity
        Dish {
          id
          name
          image
          description
          shortDescription
          price
          restaurantID
          soldOut
          dishType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dishCategoryId
          __typename
        }
        basketID
        selectedDishOptions {
          nextToken
          startedAt
          __typename
        }
        selectedDishOptionsJSON
        note
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        basketDishDishId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    userID
    restaurantID
    note
    deliveryMethod
    deliveryProvider
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBasketSubscriptionVariables,
  APITypes.OnUpdateBasketSubscription
>;
export const onDeleteBasket = /* GraphQL */ `subscription OnDeleteBasket($filter: ModelSubscriptionBasketFilterInput) {
  onDeleteBasket(filter: $filter) {
    id
    BasketDishes {
      items {
        id
        quantity
        Dish {
          id
          name
          image
          description
          shortDescription
          price
          restaurantID
          soldOut
          dishType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dishCategoryId
          __typename
        }
        basketID
        selectedDishOptions {
          nextToken
          startedAt
          __typename
        }
        selectedDishOptionsJSON
        note
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        basketDishDishId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    userID
    restaurantID
    note
    deliveryMethod
    deliveryProvider
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBasketSubscriptionVariables,
  APITypes.OnDeleteBasketSubscription
>;
export const onCreateOrderDish = /* GraphQL */ `subscription OnCreateOrderDish($filter: ModelSubscriptionOrderDishFilterInput) {
  onCreateOrderDish(filter: $filter) {
    id
    quantity
    Dish {
      id
      name
      image
      description
      shortDescription
      price
      restaurantID
      soldOut
      category {
        id
        name
        description
        imageKey
        restaurantID
        order
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      allergens {
        id
        name
        __typename
      }
      dishType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dishCategoryId
      __typename
    }
    orderID
    selectedDishOptions {
      items {
        id
        title
        description
        multipleSelection
        obligatory
        items {
          nextToken
          startedAt
          __typename
        }
        dishIDs
        restaurantID
        basketDishID
        orderDishID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    selectedDishOptionsJSON
    note
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    orderDishDishId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateOrderDishSubscriptionVariables,
  APITypes.OnCreateOrderDishSubscription
>;
export const onUpdateOrderDish = /* GraphQL */ `subscription OnUpdateOrderDish($filter: ModelSubscriptionOrderDishFilterInput) {
  onUpdateOrderDish(filter: $filter) {
    id
    quantity
    Dish {
      id
      name
      image
      description
      shortDescription
      price
      restaurantID
      soldOut
      category {
        id
        name
        description
        imageKey
        restaurantID
        order
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      allergens {
        id
        name
        __typename
      }
      dishType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dishCategoryId
      __typename
    }
    orderID
    selectedDishOptions {
      items {
        id
        title
        description
        multipleSelection
        obligatory
        items {
          nextToken
          startedAt
          __typename
        }
        dishIDs
        restaurantID
        basketDishID
        orderDishID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    selectedDishOptionsJSON
    note
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    orderDishDishId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateOrderDishSubscriptionVariables,
  APITypes.OnUpdateOrderDishSubscription
>;
export const onDeleteOrderDish = /* GraphQL */ `subscription OnDeleteOrderDish($filter: ModelSubscriptionOrderDishFilterInput) {
  onDeleteOrderDish(filter: $filter) {
    id
    quantity
    Dish {
      id
      name
      image
      description
      shortDescription
      price
      restaurantID
      soldOut
      category {
        id
        name
        description
        imageKey
        restaurantID
        order
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      allergens {
        id
        name
        __typename
      }
      dishType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dishCategoryId
      __typename
    }
    orderID
    selectedDishOptions {
      items {
        id
        title
        description
        multipleSelection
        obligatory
        items {
          nextToken
          startedAt
          __typename
        }
        dishIDs
        restaurantID
        basketDishID
        orderDishID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    selectedDishOptionsJSON
    note
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    orderDishDishId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteOrderDishSubscriptionVariables,
  APITypes.OnDeleteOrderDishSubscription
>;
export const onCreateOrder = /* GraphQL */ `subscription OnCreateOrder($filter: ModelSubscriptionOrderFilterInput) {
  onCreateOrder(filter: $filter) {
    id
    userID
    Restaurant {
      id
      adminSub
      listRanking
      name
      image {
        logoKey
        teaserKey
        __typename
      }
      minDeliveryTime
      maxDeliveryTime
      rating
      address
      lat
      lng
      Dishes {
        items {
          id
          name
          image
          description
          shortDescription
          price
          restaurantID
          soldOut
          dishType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dishCategoryId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Baskets {
        items {
          id
          userID
          restaurantID
          note
          deliveryMethod
          deliveryProvider
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      categories {
        items {
          id
          name
          restaurantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      imageStorageKey
      status
      invoices {
        items {
          id
          amount
          customerFee
          deliveryFee
          restaurantFee
          customerID
          customerEmail
          restaurantID
          paymentIntent
          paymentIntentID
          pdfInvoice
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      phone
      email
      emailPrinter
      autoPickUp
      customData
      billingDetails {
        address {
          city
          country
          line1
          line2
          postal_code
          state
          __typename
        }
        email
        name
        phone
        __typename
      }
      printerGCMToken
      businessHours {
        items {
          id
          restaurantID
          day
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      loggingEnabled
      deliveryProvider
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    total
    totalPaid
    voucherCode
    voucherAmountOff
    voucherType
    voucherPercentOff
    status
    OrderDishes {
      items {
        id
        quantity
        Dish {
          id
          name
          image
          description
          shortDescription
          price
          restaurantID
          soldOut
          dishType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dishCategoryId
          __typename
        }
        orderID
        selectedDishOptions {
          nextToken
          startedAt
          __typename
        }
        selectedDishOptionsJSON
        note
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        orderDishDishId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Driver {
      id
      name
      sub
      email
      password
      lat
      lng
      transportationMode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    read
    printed
    orderNumber
    note
    ready
    createdAt
    orderRestaurantId
    deliveryMethod
    deliveryProvider
    updatedAt
    _version
    _deleted
    _lastChangedAt
    orderDriverId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateOrderSubscriptionVariables,
  APITypes.OnCreateOrderSubscription
>;
export const onUpdateOrder = /* GraphQL */ `subscription OnUpdateOrder($filter: ModelSubscriptionOrderFilterInput) {
  onUpdateOrder(filter: $filter) {
    id
    userID
    Restaurant {
      id
      adminSub
      listRanking
      name
      image {
        logoKey
        teaserKey
        __typename
      }
      minDeliveryTime
      maxDeliveryTime
      rating
      address
      lat
      lng
      Dishes {
        items {
          id
          name
          image
          description
          shortDescription
          price
          restaurantID
          soldOut
          dishType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dishCategoryId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Baskets {
        items {
          id
          userID
          restaurantID
          note
          deliveryMethod
          deliveryProvider
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      categories {
        items {
          id
          name
          restaurantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      imageStorageKey
      status
      invoices {
        items {
          id
          amount
          customerFee
          deliveryFee
          restaurantFee
          customerID
          customerEmail
          restaurantID
          paymentIntent
          paymentIntentID
          pdfInvoice
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      phone
      email
      emailPrinter
      autoPickUp
      customData
      billingDetails {
        address {
          city
          country
          line1
          line2
          postal_code
          state
          __typename
        }
        email
        name
        phone
        __typename
      }
      printerGCMToken
      businessHours {
        items {
          id
          restaurantID
          day
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      loggingEnabled
      deliveryProvider
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    total
    totalPaid
    voucherCode
    voucherAmountOff
    voucherType
    voucherPercentOff
    status
    OrderDishes {
      items {
        id
        quantity
        Dish {
          id
          name
          image
          description
          shortDescription
          price
          restaurantID
          soldOut
          dishType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dishCategoryId
          __typename
        }
        orderID
        selectedDishOptions {
          nextToken
          startedAt
          __typename
        }
        selectedDishOptionsJSON
        note
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        orderDishDishId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Driver {
      id
      name
      sub
      email
      password
      lat
      lng
      transportationMode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    read
    printed
    orderNumber
    note
    ready
    createdAt
    orderRestaurantId
    deliveryMethod
    deliveryProvider
    updatedAt
    _version
    _deleted
    _lastChangedAt
    orderDriverId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateOrderSubscriptionVariables,
  APITypes.OnUpdateOrderSubscription
>;
export const onDeleteOrder = /* GraphQL */ `subscription OnDeleteOrder($filter: ModelSubscriptionOrderFilterInput) {
  onDeleteOrder(filter: $filter) {
    id
    userID
    Restaurant {
      id
      adminSub
      listRanking
      name
      image {
        logoKey
        teaserKey
        __typename
      }
      minDeliveryTime
      maxDeliveryTime
      rating
      address
      lat
      lng
      Dishes {
        items {
          id
          name
          image
          description
          shortDescription
          price
          restaurantID
          soldOut
          dishType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dishCategoryId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Baskets {
        items {
          id
          userID
          restaurantID
          note
          deliveryMethod
          deliveryProvider
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      categories {
        items {
          id
          name
          restaurantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      imageStorageKey
      status
      invoices {
        items {
          id
          amount
          customerFee
          deliveryFee
          restaurantFee
          customerID
          customerEmail
          restaurantID
          paymentIntent
          paymentIntentID
          pdfInvoice
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      phone
      email
      emailPrinter
      autoPickUp
      customData
      billingDetails {
        address {
          city
          country
          line1
          line2
          postal_code
          state
          __typename
        }
        email
        name
        phone
        __typename
      }
      printerGCMToken
      businessHours {
        items {
          id
          restaurantID
          day
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      loggingEnabled
      deliveryProvider
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    total
    totalPaid
    voucherCode
    voucherAmountOff
    voucherType
    voucherPercentOff
    status
    OrderDishes {
      items {
        id
        quantity
        Dish {
          id
          name
          image
          description
          shortDescription
          price
          restaurantID
          soldOut
          dishType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dishCategoryId
          __typename
        }
        orderID
        selectedDishOptions {
          nextToken
          startedAt
          __typename
        }
        selectedDishOptionsJSON
        note
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        orderDishDishId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Driver {
      id
      name
      sub
      email
      password
      lat
      lng
      transportationMode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    read
    printed
    orderNumber
    note
    ready
    createdAt
    orderRestaurantId
    deliveryMethod
    deliveryProvider
    updatedAt
    _version
    _deleted
    _lastChangedAt
    orderDriverId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteOrderSubscriptionVariables,
  APITypes.OnDeleteOrderSubscription
>;
export const onCreateDeliveryAddress = /* GraphQL */ `subscription OnCreateDeliveryAddress(
  $filter: ModelSubscriptionDeliveryAddressFilterInput
) {
  onCreateDeliveryAddress(filter: $filter) {
    id
    name
    addressData {
      street
      city
      country
      district
      formattedAddress
      lat
      lng
      __typename
    }
    userID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateDeliveryAddressSubscriptionVariables,
  APITypes.OnCreateDeliveryAddressSubscription
>;
export const onUpdateDeliveryAddress = /* GraphQL */ `subscription OnUpdateDeliveryAddress(
  $filter: ModelSubscriptionDeliveryAddressFilterInput
) {
  onUpdateDeliveryAddress(filter: $filter) {
    id
    name
    addressData {
      street
      city
      country
      district
      formattedAddress
      lat
      lng
      __typename
    }
    userID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateDeliveryAddressSubscriptionVariables,
  APITypes.OnUpdateDeliveryAddressSubscription
>;
export const onDeleteDeliveryAddress = /* GraphQL */ `subscription OnDeleteDeliveryAddress(
  $filter: ModelSubscriptionDeliveryAddressFilterInput
) {
  onDeleteDeliveryAddress(filter: $filter) {
    id
    name
    addressData {
      street
      city
      country
      district
      formattedAddress
      lat
      lng
      __typename
    }
    userID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteDeliveryAddressSubscriptionVariables,
  APITypes.OnDeleteDeliveryAddressSubscription
>;
export const onCreateRestaurantCategory = /* GraphQL */ `subscription OnCreateRestaurantCategory(
  $filter: ModelSubscriptionRestaurantCategoryFilterInput
) {
  onCreateRestaurantCategory(filter: $filter) {
    id
    name
    restaurantID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateRestaurantCategorySubscriptionVariables,
  APITypes.OnCreateRestaurantCategorySubscription
>;
export const onUpdateRestaurantCategory = /* GraphQL */ `subscription OnUpdateRestaurantCategory(
  $filter: ModelSubscriptionRestaurantCategoryFilterInput
) {
  onUpdateRestaurantCategory(filter: $filter) {
    id
    name
    restaurantID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateRestaurantCategorySubscriptionVariables,
  APITypes.OnUpdateRestaurantCategorySubscription
>;
export const onDeleteRestaurantCategory = /* GraphQL */ `subscription OnDeleteRestaurantCategory(
  $filter: ModelSubscriptionRestaurantCategoryFilterInput
) {
  onDeleteRestaurantCategory(filter: $filter) {
    id
    name
    restaurantID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteRestaurantCategorySubscriptionVariables,
  APITypes.OnDeleteRestaurantCategorySubscription
>;
export const onCreateRestaurant = /* GraphQL */ `subscription OnCreateRestaurant(
  $filter: ModelSubscriptionRestaurantFilterInput
) {
  onCreateRestaurant(filter: $filter) {
    id
    adminSub
    listRanking
    name
    image {
      logoKey
      teaserKey
      __typename
    }
    minDeliveryTime
    maxDeliveryTime
    rating
    address
    lat
    lng
    Dishes {
      items {
        id
        name
        image
        description
        shortDescription
        price
        restaurantID
        soldOut
        category {
          id
          name
          description
          imageKey
          restaurantID
          order
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        allergens {
          id
          name
          __typename
        }
        dishType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dishCategoryId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Baskets {
      items {
        id
        BasketDishes {
          nextToken
          startedAt
          __typename
        }
        userID
        restaurantID
        note
        deliveryMethod
        deliveryProvider
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    categories {
      items {
        id
        name
        restaurantID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    imageStorageKey
    status
    invoices {
      items {
        id
        amount
        customerFee
        deliveryFee
        restaurantFee
        customerID
        customerEmail
        restaurantID
        paymentIntent
        paymentIntentID
        details {
          name
          description
          price
          __typename
        }
        pdfInvoice
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    phone
    email
    emailPrinter
    autoPickUp
    customData
    billingDetails {
      address {
        city
        country
        line1
        line2
        postal_code
        state
        __typename
      }
      email
      name
      phone
      __typename
    }
    printerGCMToken
    businessHours {
      items {
        id
        restaurantID
        periods {
          label
          start
          end
          __typename
        }
        day
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    loggingEnabled
    deliveryProvider
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateRestaurantSubscriptionVariables,
  APITypes.OnCreateRestaurantSubscription
>;
export const onUpdateRestaurant = /* GraphQL */ `subscription OnUpdateRestaurant(
  $filter: ModelSubscriptionRestaurantFilterInput
) {
  onUpdateRestaurant(filter: $filter) {
    id
    adminSub
    listRanking
    name
    image {
      logoKey
      teaserKey
      __typename
    }
    minDeliveryTime
    maxDeliveryTime
    rating
    address
    lat
    lng
    Dishes {
      items {
        id
        name
        image
        description
        shortDescription
        price
        restaurantID
        soldOut
        category {
          id
          name
          description
          imageKey
          restaurantID
          order
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        allergens {
          id
          name
          __typename
        }
        dishType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dishCategoryId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Baskets {
      items {
        id
        BasketDishes {
          nextToken
          startedAt
          __typename
        }
        userID
        restaurantID
        note
        deliveryMethod
        deliveryProvider
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    categories {
      items {
        id
        name
        restaurantID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    imageStorageKey
    status
    invoices {
      items {
        id
        amount
        customerFee
        deliveryFee
        restaurantFee
        customerID
        customerEmail
        restaurantID
        paymentIntent
        paymentIntentID
        details {
          name
          description
          price
          __typename
        }
        pdfInvoice
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    phone
    email
    emailPrinter
    autoPickUp
    customData
    billingDetails {
      address {
        city
        country
        line1
        line2
        postal_code
        state
        __typename
      }
      email
      name
      phone
      __typename
    }
    printerGCMToken
    businessHours {
      items {
        id
        restaurantID
        periods {
          label
          start
          end
          __typename
        }
        day
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    loggingEnabled
    deliveryProvider
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateRestaurantSubscriptionVariables,
  APITypes.OnUpdateRestaurantSubscription
>;
export const onDeleteRestaurant = /* GraphQL */ `subscription OnDeleteRestaurant(
  $filter: ModelSubscriptionRestaurantFilterInput
) {
  onDeleteRestaurant(filter: $filter) {
    id
    adminSub
    listRanking
    name
    image {
      logoKey
      teaserKey
      __typename
    }
    minDeliveryTime
    maxDeliveryTime
    rating
    address
    lat
    lng
    Dishes {
      items {
        id
        name
        image
        description
        shortDescription
        price
        restaurantID
        soldOut
        category {
          id
          name
          description
          imageKey
          restaurantID
          order
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        allergens {
          id
          name
          __typename
        }
        dishType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dishCategoryId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Baskets {
      items {
        id
        BasketDishes {
          nextToken
          startedAt
          __typename
        }
        userID
        restaurantID
        note
        deliveryMethod
        deliveryProvider
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    categories {
      items {
        id
        name
        restaurantID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    imageStorageKey
    status
    invoices {
      items {
        id
        amount
        customerFee
        deliveryFee
        restaurantFee
        customerID
        customerEmail
        restaurantID
        paymentIntent
        paymentIntentID
        details {
          name
          description
          price
          __typename
        }
        pdfInvoice
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    phone
    email
    emailPrinter
    autoPickUp
    customData
    billingDetails {
      address {
        city
        country
        line1
        line2
        postal_code
        state
        __typename
      }
      email
      name
      phone
      __typename
    }
    printerGCMToken
    businessHours {
      items {
        id
        restaurantID
        periods {
          label
          start
          end
          __typename
        }
        day
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    loggingEnabled
    deliveryProvider
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteRestaurantSubscriptionVariables,
  APITypes.OnDeleteRestaurantSubscription
>;
