import { useCallback, useEffect, useState } from "react";

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Loader } from "@aws-amplify/ui-react";

import { DeliveryProvider, Restaurant } from "../../API";
import { RestaurantApi } from "../../api-client";
import { useAppContext } from "../../context/app-context";

export default function ToggleDeliveryProvider({
  restaurant,
}: {
  restaurant: Restaurant;
}) {
  const { restaurants, setRestaurants } = useAppContext();
  const [isTasty, setIsTasty] = useState<boolean>(true);
  const [loadingDeliveryProvider, setLoadingDeliveryProvider] =
    useState<boolean>(false);

  useEffect(() => {
    setIsTasty(
      restaurant?.deliveryProvider === DeliveryProvider.TASTY ||
        !restaurant?.deliveryProvider
    );
  }, [restaurant?.deliveryProvider]);

  const updateDeliveryProvider = useCallback(
    (isTasty: boolean) => {
      if (!restaurant?.id) {
        return alert("Restaurant ID not found");
      }

      setLoadingDeliveryProvider(true);
      RestaurantApi.updateRestaurantByID({
        id: restaurant?.id,
        deliveryProvider: isTasty
          ? DeliveryProvider.TASTY
          : DeliveryProvider.RESTAURANT,
      })
        .then((updatedRestaurant) => {
          if (updatedRestaurant) {
            // Update the restaurants array by replacing the old restaurant with the updated one
            const updatedRestaurants = (restaurants || []).map((r) =>
              r.id === updatedRestaurant.id ? updatedRestaurant : r
            );
            setRestaurants(updatedRestaurants);
            setIsTasty(isTasty);
          }
        })
        .finally(() => setLoadingDeliveryProvider(false));
    },
    [restaurant?.id, restaurants, setRestaurants]
  );

  return (
    <FormControl component="fieldset">
      {loadingDeliveryProvider && (
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            backgroundColor: "rgba(0, 0, 0, 0.1)",
          }}
        >
          <Loader />
        </div>
      )}

      <FormLabel component="legend">Liefereinstellungen</FormLabel>

      <RadioGroup
        name="deliveryProvider"
        value={isTasty ? "Tasty" : "Restaurant"}
        onChange={(event) => {
          const selectedProvider = event.target.value === "Tasty";
          updateDeliveryProvider(selectedProvider);
        }}
      >
        <FormControlLabel
          value="Tasty"
          control={<Radio size="small" disabled={loadingDeliveryProvider} />}
          label="Tasty liefert"
        />
        <FormControlLabel
          value="Restaurant"
          control={<Radio size="small" disabled={loadingDeliveryProvider} />}
          label="Restaurant liefert"
        />
      </RadioGroup>
      <FormHelperText>
        {!isTasty
          ? "Bitte beachten Sie, dass Sie für die Lieferung verantwortlich sind. Kunden werden nicht über Tasty beliefert."
          : "Tasty liefert, Sie müssen sich um nichts kümmern. Lediglich die Bestellung zubereiten und dem Fahrer übergeben."}
      </FormHelperText>
    </FormControl>
  );
}
