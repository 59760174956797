import {
  Document,
  Font,
  Image,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import moment from "moment";

// @ts-ignore
import AmbleBold from "../../assets/fonts/Amble-Bold.ttf";
// @ts-ignore
import AmbleRegular from "../../assets/fonts/Amble-Regular.ttf";
import { RestaurantInvoices, RestaurantWeeklyBill } from "../../models";
import { useAppContext } from "../../context/app-context";
import formatPrice from "../../utils/formatPrice";
import InvoiceItem from "./invoice-item";

const styles = StyleSheet.create({
  viewer: {
    width: window.innerWidth * 0.67, //the pdf viewer will take up all of the width and height
    height: window.innerHeight * 0.9,
  },
  page: {
    flexDirection: "column",
    backgroundColor: "white",
    padding: 32,
    paddingTop: 48,
  },
  section: {
    margin: 8,
    padding: 8,
  },
  positionSection: {
    margin: 16,
    padding: 8,
    paddingBottom: 2,
    marginBottom: 0,
    marginTop: 8,
  },
  headerSection: {
    margin: 16,
    marginBottom: 0,
    paddingBottom: 2,
    borderBottomWidth: 2,
  },
  revenueSection: {
    margin: 16,
    marginTop: 0,
    marginBottom: 0,
    padding: 8,
    borderTopWidth: 2,
    borderBottomWidth: 2,
    alignItems: "center",
  },
  text: {
    fontSize: 12,
    fontFamily: "Amble",
  },
  paper: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  topContainer: {
    display: "flex",
  },
  positionsContainer: {
    display: "flex",
  },
  positionRow: {
    marginBottom: 16,
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

Font.register({
  family: "Amble",
  fonts: [
    {
      src: AmbleRegular,
    },
    {
      src: AmbleBold,
      fontWeight: "bold",
    },
  ],
});

type RestaurantMetadata = {
  address: string;
  customerID?: string;
  name?: string;
};

type InvoiceMetadata = {
  dateRange: string;
  invoiceNumber: string;
  ordersCount: string;
  weeklyBill?: RestaurantWeeklyBill;
  restaurantFee?: number;
};

export default function InvoicePDF({
  invoiceMetadata,
  restaurantMetadata,
  weeklyBill,
}: {
  weeklyBill: RestaurantWeeklyBill | null;
  invoiceMetadata: InvoiceMetadata;
  restaurantMetadata: RestaurantMetadata;
}) {
  const { appConfig } = useAppContext();

  const totalGrossPrice = weeklyBill?.totalGrossPrice || 0;
  const totalRefunds = weeklyBill?.totalRefunds || 0;
  const salesTax = weeklyBill?.salesTax || 0;
  const totalNetPrice = weeklyBill?.totalNetPrice || 0;

  const netRevenue = totalGrossPrice - totalRefunds;
  const serviceFeeInEuro = (netRevenue / 100) * (appConfig?.restaurantFee || 1);
  const serviceFeeSalesTaxInEuro = serviceFeeInEuro * 0.07;
  const payout = netRevenue - (serviceFeeInEuro + serviceFeeSalesTaxInEuro);
  const invoiceClearing = serviceFeeInEuro + serviceFeeSalesTaxInEuro;
  const paidOnline = totalGrossPrice;

  const renderBriefPapier = () => {
    return (
      <Image
        source={require("../../assets/Briefpapier.png")}
        style={styles.paper}
      />
    );
  };
  const renderTopContainer = () => {
    return (
      <View style={styles.topContainer}>
        <View style={[styles.section, { marginBottom: 0, maxWidth: "40%" }]}>
          <Text style={styles.text}>{restaurantMetadata.address}</Text>
        </View>
        <View style={[styles.section, { marginTop: 0 }]}>
          <Text style={styles.text}>
            Kundennummer: {restaurantMetadata.customerID}
          </Text>
          <Text style={styles.text}>
            Rechnungsnummer: {invoiceMetadata.invoiceNumber}
          </Text>
          <Text style={styles.text}>Zeitraum: {invoiceMetadata.dateRange}</Text>
        </View>
      </View>
    );
  };

  const renderPositions = () => {
    return (
      <View style={styles.positionsContainer}>
        <View style={styles.headerSection}>
          <Text style={styles.text}>
            Folgende Leistungen stellen wir Ihnen in Rechnung:
          </Text>
        </View>

        <View style={styles.positionSection}>
          <Row>
            <View style={{ width: "80%" }}>
              <Row>
                <Text style={styles.text}>
                  {weeklyBill?.data?.length +
                    " Bestellungen im Wert von (netto)"}
                </Text>
                <Text style={styles.text}>{formatPrice(totalNetPrice)}</Text>
              </Row>

              <Row>
                <Text style={styles.text}>{"+ 7% Umsatzsteuer"}</Text>
                <Text style={styles.text}>{formatPrice(salesTax)}</Text>
              </Row>
            </View>

            <Text style={styles.text}>{formatPrice(totalGrossPrice)}</Text>
          </Row>

          <View style={{ margin: 4 }} />

          <Row>
            <View style={{ width: "80%" }}>
              <Row>
                <Text style={styles.text}>{"Abzüglich Rückerstattungen"}</Text>
                <Text style={styles.text}>{formatPrice(totalRefunds)}</Text>
              </Row>
            </View>

            <Text style={styles.text}>{formatPrice(netRevenue)}</Text>
          </Row>

          <View style={{ margin: 4 }} />

          <Row>
            <View style={{ width: "80%" }}>
              <Row>
                <Text style={styles.text}>
                  {`Servicegebühr ${
                    invoiceMetadata.restaurantFee
                  }% von ${formatPrice(totalGrossPrice)}`}
                </Text>
                <Text style={styles.text}>{formatPrice(serviceFeeInEuro)}</Text>
              </Row>

              <Row>
                <Text style={styles.text}>{"+ 7% Umsatzsteuer"}</Text>
                <Text style={[styles.text]}>
                  {formatPrice(serviceFeeSalesTaxInEuro)}
                </Text>
              </Row>
            </View>

            <Text style={styles.text}>{formatPrice(invoiceClearing)}</Text>
          </Row>

          <View style={{ marginVertical: 8 }} />

          <Row>
            <Text style={styles.text}>
              {"Verrechnet mit eingegangenen Onlinebezahlungen"}
            </Text>
            <Text style={[styles.text]}>{formatPrice(invoiceClearing)}</Text>
          </Row>

          <View style={{ marginVertical: 8 }} />

          <Row>
            <Text style={styles.text}>{"Offener Rechnungsbetrag"}</Text>
            <Text style={[styles.text]}>{formatPrice(0)}</Text>
          </Row>

          <View style={{ marginVertical: 8 }} />
        </View>
      </View>
    );
  };

  const renderRevenue = () => {
    return (
      <View style={styles.revenueSection}>
        <Text style={[styles.text, { textAlign: "center" }]}>
          Ihr Umsatz in der Zeit vom {invoiceMetadata.dateRange} beträgt
        </Text>
        <Text style={[styles.text, { fontWeight: "bold" }]}>
          {formatPrice(netRevenue)}
        </Text>
      </View>
    );
  };

  const renderDetails = () => {
    return (
      <View style={styles.positionSection}>
        <Text style={[styles.text, { fontWeight: "bold", marginBottom: 8 }]}>
          Abrechnungsgutschrift:
        </Text>
        <View style={styles.positionRow}>
          <Text style={styles.text}>Einzelauflistung:</Text>
        </View>

        <View style={[styles.positionRow, { marginBottom: 0 }]}>
          <Text style={styles.text}>{"Gesamt"}</Text>
          <Text style={styles.text}>
            {invoiceMetadata.ordersCount} Bestellungen im Wert von{" "}
            {formatPrice(paidOnline)}
          </Text>
        </View>
        <View style={styles.positionRow}>
          <Text style={styles.text}>{"Online bezahlt"}</Text>
          <Text style={styles.text}>
            {invoiceMetadata.ordersCount} Bestellungen im Wert von{" "}
            {formatPrice(paidOnline)}
          </Text>
        </View>

        <View style={[styles.positionRow, { marginBottom: 0 }]}>
          <Text style={styles.text}>{"Umsatz"}</Text>
          <Text style={styles.text}>{formatPrice(netRevenue)}</Text>
        </View>
        <View style={styles.positionRow}>
          <Text style={styles.text}>{"./. Rechnungsausgleich"}</Text>
          <Text style={styles.text}>{formatPrice(invoiceClearing)}</Text>
        </View>
        <View style={styles.positionRow}>
          <Text style={styles.text}>{"Ausbezahlter Betrag"}</Text>
          <View style={{ borderBottomWidth: 1, marginBottom: 2 }}>
            <Text
              style={[
                styles.text,
                {
                  textDecoration: "underline",
                },
              ]}
            >
              {formatPrice(payout)}
            </Text>
          </View>
        </View>
      </View>
    );
  };

  const renderFoodDeliveryTaxNote = () => {
    return (
      <View style={styles.positionSection}>
        <Text style={[styles.text]}>
          Es handelt sich um Essenslieferungen und demzufolge wurde der
          ermäßigte Umsatzsteuersatz berechnet.
        </Text>
      </View>
    );
  };

  const chunks = (
    itemsPerPage: number,
    invoiceTransaction?: RestaurantInvoices[]
  ) =>
    Array.from(
      new Array(Math.ceil(invoiceTransaction?.length || 0 / itemsPerPage)),
      (_, i) =>
        invoiceTransaction?.slice(
          i * itemsPerPage,
          i * itemsPerPage + itemsPerPage
        )
    );

  return (
    <PDFViewer style={styles.viewer}>
      <Document>
        <Page size="A4" style={styles.page}>
          {renderBriefPapier()}
          {renderTopContainer()}
          {renderPositions()}
          {renderRevenue()}
          {renderDetails()}
          {renderFoodDeliveryTaxNote()}
        </Page>

        {chunks(
          7,
          // @ts-ignore
          invoiceMetadata?.weeklyBill?.data?.sort(
            // @ts-ignore
            (a, b) => moment(a?.invoiceCreatedAt) - moment(b?.invoiceCreatedAt)
          )
        ).map((chunk, index) => {
          return (
            <Page key={index} size="A4" style={[styles.page, {}]}>
              {renderBriefPapier()}
              <View style={{ marginTop: 64, paddingHorizontal: 16 }}>
                {chunk?.map((item: RestaurantInvoices) => {
                  return <InvoiceItem key={item.invoiceID} {...item} />;
                })}
              </View>
            </Page>
          );
        })}
      </Document>
    </PDFViewer>
  );
}

function Row({ children }: any) {
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-end",
      }}
    >
      {children}
    </View>
  );
}
