import moment from "moment";
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { Card } from "antd";
import { Loader } from "@aws-amplify/ui-react";

import { DriverApi, OrderApi } from "../../api-client";
import * as API from "../../API";
import DriverAdd from "./driver-add";
import DriversList from "./drivers-list";
import DriversMap from "./drivers-map";

const startOfCurrentMonth = moment().startOf("month").toISOString();
const startOfPreviousMonth = moment()
  .subtract(1, "month")
  .startOf("month")
  .toISOString();
const startOfTwoMonthsAgo = moment()
  .subtract(2, "month")
  .startOf("month")
  .toISOString();
const startOfThreeMonthsAgo = moment()
  .subtract(3, "month")
  .startOf("month")
  .toISOString();

export default function Drivers() {
  const [tab, setTab] = useState<"manage" | "map">("map");
  const [loading, setLoading] = useState<boolean>(false);
  const [drivers, setDrivers] = useState<API.Driver[]>([]);
  const [selectedDriver, setSelectedDriver] = useState<API.Driver | null>(null);
  const [orderCounts, setOrderCounts] = useState<
    Record<string, { [month: string]: number }>
  >({});

  const fetchDrivers = () => {
    setLoading(true);
    DriverApi.fetchList({
      limit: 10000,
      filter: {
        updatedAt: { between: [startOfThreeMonthsAgo, startOfCurrentMonth] },
      },
    })
      .then(setDrivers)
      .finally(() => setTimeout(() => setLoading(false), 1000));
  };

  useEffect(() => {
    fetchDrivers();

    const updateSub = DriverApi.updateSubscription({
      filter: {
        updatedAt: { between: [startOfThreeMonthsAgo, startOfCurrentMonth] },
      },
    }).subscribe({
      next: ({ provider, value }) => {
        console.log("[OBSERVE DRIVER]", { value });

        setDrivers((prevDrivers) => {
          const existingDriver = prevDrivers.find(
            (d) => d.id === value.data?.onUpdateDriver?.id
          );
          if (existingDriver) {
            return prevDrivers.map((d) =>
              d.id === value.data?.onUpdateDriver?.id
                ? (value.data?.onUpdateDriver as API.Driver)
                : (d as API.Driver)
            );
          } else {
            return [...prevDrivers, value.data?.onUpdateDriver] as API.Driver[];
          }
        });
      },
      error: (error) => console.warn(error),
    });

    return () => updateSub.unsubscribe();
  }, [tab]);

  const sortedDrivers = drivers
    .sort(
      (a, b) =>
        // @ts-ignore
        moment(tab === "manage" ? a.createdAt : a.updatedAt) -
        // @ts-ignore
        moment(tab === "manage" ? b.createdAt : b.updatedAt)
    )
    .reverse();

  return (
    <Card
      loading={loading}
      title={
        <Box>
          {loading && <Loader />}
          <Box>
            <Button
              fullWidth
              variant={tab === "map" ? "contained" : "text"}
              onClick={() => setTab("map")}
            >
              Map
            </Button>
          </Box>
          <Box>
            <Button
              fullWidth
              variant={tab === "manage" ? "contained" : "text"}
              onClick={() => setTab("manage")}
            >
              Manage
            </Button>
          </Box>
        </Box>
      }
    >
      <Typography>{"Fahrer insgesamt: " + sortedDrivers.length}</Typography>

      {tab === "manage" && (
        <>
          <Box>
            <Typography variant="h5">Tasty Fahrer</Typography>
            <Typography>Übersicht aller Fahrer/innen</Typography>
            <Button onClick={fetchDrivers}>Fahrer aktualisieren</Button>
          </Box>
          <Divider style={{ marginTop: 8 }} />
          <DriverAdd onCreateDriverSuccess={fetchDrivers} />
          <Divider style={{ marginTop: 8 }} />
          <DriversList data={sortedDrivers} onReload={fetchDrivers} />
        </>
      )}

      {tab === "map" && (
        <>
          <Box>
            <Typography variant="h5">Kartenansicht</Typography>
            <Typography>Übersicht aller Fahrer/innen</Typography>
            <Button onClick={() => setSelectedDriver(null)}>
              Fahrer zurücksetzen
            </Button>
          </Box>
          <Box flexDirection={"row"} display="flex">
            <DriversMap
              drivers={sortedDrivers}
              selectedDriver={selectedDriver}
              setSelectedDriver={setSelectedDriver}
            />

            <List>
              {sortedDrivers.map((driver) => (
                <DriverItem
                  driver={driver}
                  setSelectedDriver={setSelectedDriver}
                />
              ))}
            </List>
          </Box>
        </>
      )}
    </Card>
  );
}

function DriverItem({
  driver,
  setSelectedDriver,
}: {
  driver: API.Driver;
  setSelectedDriver: (driver: API.Driver) => void;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [orderCounts, setOrderCounts] = useState<
    Record<string, { [month: string]: number }>
  >({});

  const fetchOrders = () => {
    setLoading(true);

    OrderApi.fetchList({
      limit: 10000,
      filter: {
        status: { eq: API.OrderStatus.COMPLETED },
        createdAt: { between: [startOfThreeMonthsAgo, startOfCurrentMonth] },
        orderDriverId: { eq: driver.id },
      },
    })
      .then((orders) => {
        const counts = orders.reduce((acc, order) => {
          const driverId = order.orderDriverId;
          if (driverId) {
            const month = moment(order.createdAt).format("MMMM");
            if (!acc[driverId]) {
              acc[driverId] = {};
            }
            acc[driverId][month] = (acc[driverId][month] || 0) + 1;
          }
          return acc;
        }, {} as Record<string, { [month: string]: number }>);
        setOrderCounts(counts);
      })
      .finally(() => setTimeout(() => setLoading(false), 1000));
  };

  useEffect(() => {
    if (!driver.id) {
      return;
    }

    fetchOrders();
  }, [driver.id]);

  if (loading) {
    return <Loader />;
  }

  return (
    <ListItem key={driver.id}>
      <ListItemButton
        style={{
          flexDirection: "column",
          alignItems: "flex-start",
          borderBottom: "1px solid #f0f0f0",
        }}
        disabled={!driver.lat || !driver.lng}
        onClick={() => {
          setSelectedDriver(driver);
        }}
      >
        <ListItemText
          disableTypography={true}
          style={{ fontWeight: "bold", fontSize: 20 }}
        >
          {driver.name}
        </ListItemText>
        <ListItemText>
          {"zuletzt aktualisiert: " + moment(driver.updatedAt).fromNow()}
        </ListItemText>

        <ListItemText>
          Abgeschlossene Aufträge:
          <ul>
            <li>
              {moment(startOfCurrentMonth).format("MMMM")}:{" "}
              {orderCounts[driver.id]?.[
                moment(startOfCurrentMonth).format("MMMM")
              ] || 0}
            </li>
            <li>
              {moment(startOfPreviousMonth).format("MMMM")}:{" "}
              {orderCounts[driver.id]?.[
                moment(startOfPreviousMonth).format("MMMM")
              ] || 0}
            </li>
            <li>
              {moment(startOfTwoMonthsAgo).format("MMMM")}:{" "}
              {orderCounts[driver.id]?.[
                moment(startOfTwoMonthsAgo).format("MMMM")
              ] || 0}
            </li>
          </ul>
        </ListItemText>
        {(!driver.lat || !driver.lng) && (
          <ListItemText style={{ color: "red", textTransform: "uppercase" }}>
            Keine Geo-coordinaten...
          </ListItemText>
        )}
      </ListItemButton>

      <ListItemText>{"ID: " + driver.id}</ListItemText>
    </ListItem>
  );
}
