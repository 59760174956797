// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const DeliveryMethod = {
  "DELIVERY": "DELIVERY",
  "PICKUP": "PICKUP"
};

const DeliveryProvider = {
  "RESTAURANT": "RESTAURANT",
  "TASTY": "TASTY"
};

const DishType = {
  "FOOD": "FOOD",
  "DRINK_ALCOHOLIC": "DRINK_ALCOHOLIC",
  "DRINK_NON_ALCOHOLIC": "DRINK_NON_ALCOHOLIC"
};

const TransportationModes = {
  "DRIVING": "DRIVING",
  "BICYCLING": "BICYCLING"
};

const OrderStatus = {
  "PENDING": "PENDING",
  "NEW": "NEW",
  "COOKING": "COOKING",
  "READY_FOR_PICKUP": "READY_FOR_PICKUP",
  "PICKED_UP": "PICKED_UP",
  "COMPLETED": "COMPLETED",
  "ACCEPTED": "ACCEPTED",
  "DECLINED_BY_RESTAURANT": "DECLINED_BY_RESTAURANT"
};

const RestaurantStatus = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE"
};

const { Invoice, DishCategory, DishOptionItem, DishOption, Dish, BusinessHourRestaurant, BusinessHourTasty, AppConfig, Driver, User, BasketDish, Basket, OrderDish, Order, DeliveryAddress, RestaurantCategory, Restaurant, PaymentStatus, CustomerInvoice, RestaurantWeeklyBill, RestaurantInvoices, PaymentIntentMetaData, PaymentIntent, InvoiceOptionItem, InvoiceOptions, InvoiceDetail, Allergens, Maintenance, OrderStop, BusinessHourPeriod, Banner, AddressData, ImageData, Charge, BillingDetails, Address, Refunds } = initSchema(schema);

export {
  Invoice,
  DishCategory,
  DishOptionItem,
  DishOption,
  Dish,
  BusinessHourRestaurant,
  BusinessHourTasty,
  AppConfig,
  Driver,
  User,
  BasketDish,
  Basket,
  OrderDish,
  Order,
  DeliveryAddress,
  RestaurantCategory,
  Restaurant,
  DeliveryMethod,
  DeliveryProvider,
  DishType,
  TransportationModes,
  OrderStatus,
  RestaurantStatus,
  PaymentStatus,
  CustomerInvoice,
  RestaurantWeeklyBill,
  RestaurantInvoices,
  PaymentIntentMetaData,
  PaymentIntent,
  InvoiceOptionItem,
  InvoiceOptions,
  InvoiceDetail,
  Allergens,
  Maintenance,
  OrderStop,
  BusinessHourPeriod,
  Banner,
  AddressData,
  ImageData,
  Charge,
  BillingDetails,
  Address,
  Refunds
};