import { useLayoutEffect, useState } from "react";

function useAppLoader() {
  const [appInitialized, setAppInitialized] = useState(false);

  useLayoutEffect(() => {
    setTimeout(() => !appInitialized && setAppInitialized(true), 2000);
  }, []);

  return { appInitialized };
}

export default useAppLoader;
