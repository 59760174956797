import { Typography } from "antd";

import { useAppContext } from "../../context/app-context";

export default function AdminInfo() {
  const { authUser } = useAppContext();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: 16,
        margin: 16,
      }}
    >
      <Typography.Text strong style={{ textAlign: "left" }}>
        {"Admin User"}
      </Typography.Text>

      {authUser?.attributes && (
        <>
          <Typography.Text copyable ellipsis style={{ textAlign: "left" }}>
            {authUser.attributes.sub}
          </Typography.Text>

          <Typography.Text copyable ellipsis style={{ textAlign: "left" }}>
            {authUser.attributes.email}
          </Typography.Text>
        </>
      )}
    </div>
  );
}
