import React, { useCallback } from "react";
import { Storage } from "aws-amplify";
import { S3ProviderListOutputItem } from "@aws-amplify/storage";

import moment from "moment";
import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Modal,
  Typography,
} from "@mui/material";

import { Restaurant } from "../../API";
import { useAppContext } from "../../context/app-context";
import formatPrice from "../../utils/formatPrice";
import InvoicePDF from "./invoice-pdf";

type Summarize = {
  restaurantID: string;
  totalGrossPrice: number;
  totalRefunds: number;
  salesTax: number;
  totalNetPrice: number;
  invoicesCount: number;
  restaurantInvoices: {
    customerID: string;
    hasRefund: boolean;
    invoiceCreatedAt: string;
    invoiceID: string;
    invoiceTotalNet: number;
    invoiceTotalWithRefund: number;
    orderID: string;
    paymentIntentID: string;
    refundAmount: number;
    restaurantID: string;
    tastyRefundsCounter: number;
    voucherChargedTo: "TASTY" | "RESTAURANT"; // RESTURAUANT voucher not implemented yet
    voucherCode: string;
  }[];
};

type SummarizeList = Summarize[];

export type InvoicePDFModalData = {
  dateRange: string;
  invoice: Summarize;
  restaurant: Restaurant;
};

// const _deliveryFee = 4.9; // seit 05.02.2024
const _deliveryFee = 4.99; // seit 05.02.2024

export default function Invoices() {
  const [modalData, setModalData] = React.useState<InvoicePDFModalData>();
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [storageInvoices, setStorageInvoices] = React.useState<
    S3ProviderListOutputItem[]
  >([]);

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  const onClickInvoice = (
    dateRange: string,
    invoice: Summarize,
    restaurant: Restaurant
  ) => {
    showModal();
    setModalData({
      dateRange,
      invoice,
      restaurant,
    });
  };

  React.useEffect(() => {
    setLoading(true);
    Storage.list("invoices/summarize/", {
      level: "public",
      pageSize: "ALL",
    })
      .then(({ results, hasNextToken }) => {
        console.log("Storage", { results });

        const filtered = results
          .sort((a, b) => {
            const aDate = a.key
              ?.substring(a.key.lastIndexOf("/") + 1)
              .split("---")[0];
            const bDate = b.key
              ?.substring(b.key.lastIndexOf("/") + 1)
              .split("---")[0];
            // @ts-ignore
            return new Date(bDate).getTime() - new Date(aDate).getTime();
          })
          .filter((a) => a.key?.includes(".json")); // only request json data

        setStorageInvoices(filtered);
      })
      .catch((err) => console.error("Storage error", err))
      .finally(() =>
        setTimeout(() => {
          setLoading(false);
        }, 1000)
      );
  }, []);

  return (
    <Box>
      {loading && <h1>Loading...</h1>}

      {storageInvoices.map((invoice) => {
        return (
          <Box key={invoice.key}>
            <InvoiceItem invoice={invoice} onClickInvoice={onClickInvoice} />
          </Box>
        );
      })}

      <Modal open={visible} onClose={hideModal}>
        <Box sx={style}>
          {modalData && <InvoicePDF modalData={modalData} />}
        </Box>
      </Modal>
    </Box>
  );
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  //border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function InvoiceItem({
  invoice,
  onClickInvoice,
}: {
  invoice: S3ProviderListOutputItem;
  onClickInvoice: (
    dateRange: string,
    invoice: Summarize,
    restaurant: Restaurant
  ) => void;
}) {
  const [dateRange, setDateRange] = React.useState<string>("");
  const [summarizeList, setSummarizeList] = React.useState<SummarizeList>([]);
  const { restaurants, appConfig } = useAppContext();

  const totalRevenue = summarizeList.reduce(
    // hier ist bereits die delivery fee sowie customer fee abgezogen (keine refunds)
    (acc, cur) => acc + cur.totalGrossPrice,
    0
  );

  const totalOrdersCount = summarizeList.reduce(
    // hier ist bereits die delivery fee sowie customer fee abgezogen (keine refunds)
    (acc, cur) => acc + cur.invoicesCount,
    0
  );

  const tastyRevenue = totalRevenue * 0.25;
  const restaurantsRevenue = totalRevenue * 0.75;
  const payout = totalRevenue - tastyRevenue;

  React.useEffect(() => {
    if (!invoice.key) {
      return;
    }

    async function getStorageInvoice() {
      if (!invoice.key) {
        return;
      }

      var ext = invoice.key.substring(invoice.key.lastIndexOf(".") + 1);

      if (ext !== "json") {
        return;
      }

      const result = await Storage.get(invoice.key!, {
        download: true,
        level: "public",
        contentType: "application/json",
        cacheControl: "no-cache",
        progressCallback(progress: any) {
          console.log(`Downloaded: ${progress.loaded}/${progress.total}`);
        },
      });

      // @ts-ignore
      const text = await result.Body.text();
      const _summarize = JSON.parse(text) as SummarizeList;

      const start = invoice.key
        ?.substring(invoice.key.lastIndexOf("/") + 1)
        .split("---")[0];
      const end = invoice.key
        ?.substring(invoice.key.lastIndexOf("/") + 1)
        .split("---")[1]
        .replace(".json", "");

      const dateRange =
        moment(start).format("DD.MM.YYYY") +
        " - " +
        moment(end).format("DD.MM.YYYY");

      setSummarizeList(_summarize);
      setDateRange(dateRange);
    }

    getStorageInvoice();
  }, [invoice.key]);

  const onClickInvoicePDF = useCallback(
    async (restaurant: Restaurant, data: Summarize) => {
      onClickInvoice(dateRange, data, restaurant);
    },
    [dateRange]
  );

  return (
    <Box padding={1}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box>
            <Typography variant="inherit" fontWeight={"700"}>
              Zeitraum {dateRange}
            </Typography>

            <Typography variant="inherit">
              Gesamt-Umsatz: {formatPrice(totalRevenue)}
            </Typography>
            <Typography variant="inherit">
              Tasty-Umsatz: {formatPrice(tastyRevenue)}
            </Typography>
            <Typography variant="inherit">
              Auszahlung: {formatPrice(payout)}
            </Typography>
          </Box>
        </AccordionSummary>

        <AccordionDetails>
          <Box>
            {summarizeList.map((data, index) => {
              const restaurant = restaurants?.find(
                (restaurant) => restaurant.id === data.restaurantID
              );
              return (
                <Box
                  flexDirection={"row"}
                  display={"flex"}
                  alignItems={"center"}
                  padding={0.25}
                >
                  <Box flexGrow={1}>
                    <Accordion key={`item-${index}-${data.restaurantID}`}>
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Box>
                          <Typography>{restaurant?.name}</Typography>
                          <Typography variant="caption">
                            {formatPrice(
                              data.totalGrossPrice -
                                data.totalRefunds -
                                data.invoicesCount * _deliveryFee
                            )}
                          </Typography>
                        </Box>
                      </AccordionSummary>

                      <AccordionDetails>
                        <Box padding={1}>
                          <Typography variant="subtitle2">
                            Brutto-Umsatz: {formatPrice(data.totalGrossPrice)}
                          </Typography>
                          <Typography variant="subtitle2">
                            Netto-Umsatz: {formatPrice(data.totalNetPrice)}
                          </Typography>
                          <Typography variant="subtitle2">
                            Rückerstattungen: {formatPrice(data.totalRefunds)}
                          </Typography>
                          <Typography variant="subtitle2">
                            Mwst: {formatPrice(data.salesTax)}
                          </Typography>
                          <Typography variant="subtitle2">
                            Aufträge: {data.invoicesCount}
                          </Typography>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                  <Box>
                    <Button
                      disabled={!appConfig || !restaurant}
                      onClick={() =>
                        restaurant && onClickInvoicePDF(restaurant, data)
                      }
                    >
                      PDF
                    </Button>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
