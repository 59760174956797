import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Modal,
  Typography,
} from "@mui/material";
import { Delete, Remove } from "@mui/icons-material";
import moment from "moment";
import { TimePicker } from "antd";

import {
  BusinessHourPeriod,
  BusinessHourPeriodInput,
  BusinessHourTasty,
} from "../../API";
import { useAppContext } from "../../context/app-context";
import { BusinessHourTastyApi } from "../../api-client";

export default function BusinessHours() {
  const { appConfig } = useAppContext();
  const [businessHours, setBusinessHours] = useState<BusinessHourTasty[]>([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [dateRange, setDateRange] = useState<string[]>([]);
  const [label, setLabel] = useState<string>("");

  console.info({ appConfig });

  const handleClose = () => {
    setModalOpen(false);
    setDateRange([]);
    setLabel("");
    fetchBusinessHours();
  };
  const handleOpen = () => setModalOpen(true);

  useEffect(() => {
    if (!appConfig?.id) {
      return;
    }

    fetchBusinessHours();
  }, [appConfig?.id]);

  async function fetchBusinessHours() {
    if (appConfig?.id) {
      const businessHours = await BusinessHourTastyApi.fetchList({
        filter: { appConfigID: { eq: appConfig?.id } },
      });

      setBusinessHours(businessHours);
    } else {
      alert("App ID is missing");
    }
  }

  const deleteBusinessHour = async (businessHour: BusinessHourTasty) => {
    await BusinessHourTastyApi.deleteByID(businessHour.id);

    fetchBusinessHours();
  };

  const deleteBusinessHourPeriod = async (
    businessHour: BusinessHourTasty,
    period: BusinessHourPeriod
  ) => {
    const reducedPeriods = businessHour.periods?.filter(
      (b) =>
        (b?.start! + b?.end!).toString() !==
        (period.start! + period.end!).toString()
    );

    if (reducedPeriods) {
      await BusinessHourTastyApi.update({
        id: businessHour.id,
        _version: businessHour._version,
        periods: reducedPeriods,
      });

      fetchBusinessHours();
    }
  };

  const createNewBusinessHour = async (checkedArray: number[]) => {
    if (!appConfig?.id) {
      return alert("Keine AppConfig ID gefunden");
    }

    for await (const day of checkedArray) {
      const foundBusinessHour = businessHours.find((b) => b.day === day);

      if (foundBusinessHour) {
        const orig = await BusinessHourTastyApi.fetchItem({
          id: foundBusinessHour.id,
        });

        if (!orig) {
          return alert("Öffnungszeit kann nicht hinzugefügt werden");
        }

        await BusinessHourTastyApi.update({
          id: foundBusinessHour?.id,
          _version: orig?._version,
          periods: [
            ...(foundBusinessHour?.periods || []),
            {
              start: dateRange[0],
              end: dateRange[1],
            },
          ] as BusinessHourPeriodInput[],
        });
      } else {
        await BusinessHourTastyApi.create({
          appConfigID: appConfig?.id,
          day: day,
          periods: [
            { start: dateRange[0].toString(), end: dateRange[1].toString() },
          ],
        });
      }
    }

    handleClose();
  };

  return (
    <>
      <Box flexDirection={"row"} display="flex" alignItems={"center"}>
        <Button
          sx={{ width: "100%", bgcolor: "background.paper" }}
          variant="outlined"
          color="secondary"
          onClick={handleOpen}
        >
          Schicht hinzufügen
        </Button>
      </Box>

      <List dense={false} sx={{ width: "100%", bgcolor: "background.paper" }}>
        {businessHours
          .sort((a, b) => a.day! - b.day!)
          .map((bh) => {
            return (
              <ListItem
                key={bh.id}
                alignItems="center"
                divider
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete-business-hour"
                    onClick={() => deleteBusinessHour(bh)}
                  >
                    <Delete />
                  </IconButton>
                }
              >
                <ListItemText
                  style={{ width: 50 }}
                  secondary={moment.weekdays(bh.day as number)}
                />
                <ListItemText>
                  {bh.periods?.map((period, index) => {
                    return (
                      <Box
                        key={index}
                        flexDirection={"row"}
                        display={"flex"}
                        alignItems={"center"}
                      >
                        <ListItemText key={index}>
                          {period?.start} Uhr - {period?.end} Uhr
                        </ListItemText>

                        <IconButton
                          size="small"
                          color={"warning"}
                          onClick={() => deleteBusinessHourPeriod(bh, period!)}
                        >
                          <Remove />
                        </IconButton>
                      </Box>
                    );
                  })}
                </ListItemText>
              </ListItem>
            );
          })}
      </List>

      <Modal open={modalOpen} onClose={handleClose}>
        <Card sx={modalStyle}>
          <Box padding={1}>
            <Typography fontWeight={"bold"}>
              {"Neue Schicht anlegen"}
            </Typography>
          </Box>
          {/*  <Box padding={1}>
            <TextField
              size="small"
              id="outlined-basic"
              label="Beschreibung (optional)"
              variant="outlined"
              value={label}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setLabel(event.target.value);
              }}
            />
          </Box> */}
          <Box padding={1}>
            <TimePicker.RangePicker
              allowClear
              size="large"
              format={"HH:mm"}
              /* value={[dayjs(parsedOpeningHours?.time?.[0]), dayjs(parsedOpeningHours?.time?.[1])]} */
              placeholder={["Start", "Ende"]}
              onCalendarChange={(v, stringDates, info) => {
                if (stringDates[0].length > 0 && stringDates[1].length > 0) {
                  return setDateRange(stringDates);
                }
                console.info("START and END musst be defined");
              }}
            />
          </Box>

          <Box padding={1}>
            <ControlledWeekdaySelection
              onPressSave={createNewBusinessHour}
              enableDaySelection={dateRange.length > 0}
            />
          </Box>
        </Card>
      </Modal>
    </>
  );
}

function ControlledWeekdaySelection({
  onPressSave,
  enableDaySelection,
}: {
  onPressSave: (checkedArray: number[]) => void;
  enableDaySelection: boolean;
}) {
  const [checkedArray, setChecked] = useState<number[]>([]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      setChecked((prev) => [...prev, parseInt(event.target.value)]);
    } else {
      setChecked((prev) =>
        prev.filter((c) => c !== parseInt(event.target.value))
      );
    }
  };

  return (
    <FormControl>
      <FormLabel id="demo-radio-buttons-group-label">
        Wochentage auswählen
      </FormLabel>
      <FormGroup>
        {moment.weekdays().map((weekday, index) => {
          return (
            <FormControlLabel
              key={index}
              value={index}
              disabled={!enableDaySelection}
              control={
                <Checkbox
                  size="small"
                  checked={checkedArray.includes(index)}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={weekday}
            />
          );
        })}
      </FormGroup>
      <Button
        style={{ marginTop: 16 }}
        variant="outlined"
        onClick={() => onPressSave(checkedArray)}
      >
        Speichern
      </Button>
    </FormControl>
  );
}

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 720,
  bgcolor: "background.paper",
  //border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
