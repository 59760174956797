import { SelectedDishOption } from "../types/selected-dish-option-json.interface";
import isJSON from "./isJSON";

export default function parseSelectedDishOptionsJSONtoArray(
  selectedDishOptionsJSON: string | null | undefined
) {
  /* console.debug({
    selectedDishOptionsJSON: selectedDishOptionsJSON,
  }); */

  let listData: SelectedDishOption[] = [];

  if (isJSON(selectedDishOptionsJSON)) {
    listData = (
      selectedDishOptionsJSON ? JSON.parse(selectedDishOptionsJSON) : []
    ) as SelectedDishOption[];
  }

  if ("items" in listData) {
    listData = listData.items as SelectedDishOption[];
  }

  return listData;
}
