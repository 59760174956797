import { GraphQLQuery } from "@aws-amplify/api";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import {
  BusinessHourTasty,
  CreateBusinessHourTastyInput,
  CreateBusinessHourTastyMutation,
  CreateBusinessHourTastyMutationVariables,
  DeleteBusinessHourTastyMutation,
  DeleteBusinessHourTastyMutationVariables,
  GetBusinessHourTastyQuery,
  GetBusinessHourTastyQueryVariables,
  ListBusinessHourTastiesQuery,
  ListBusinessHourTastiesQueryVariables,
  UpdateBusinessHourTastyInput,
  UpdateBusinessHourTastyMutation,
} from "../API";
import removeTypename from "./utils/remove-typename";

export async function fetchItem(variables: GetBusinessHourTastyQueryVariables) {
  try {
    const response = await API.graphql<GraphQLQuery<GetBusinessHourTastyQuery>>(
      graphqlOperation(queries.getBusinessHourTasty, {
        ...variables,
        filter: { _deleted: { ne: true } },
      } as ListBusinessHourTastiesQueryVariables)
    );
    console.debug(
      "[BUSINESS_HOURS_TASTY] fetchBusinessHours",
      response.data?.getBusinessHourTasty
    );
    return response.data?.getBusinessHourTasty as BusinessHourTasty;
  } catch (error) {
    console.error("[BUSINESS_HOURS_TASTY] fetchBusinessHours", error);
    return null;
  }
}

export async function fetchList(
  variables: ListBusinessHourTastiesQueryVariables
) {
  try {
    const response = await API.graphql<
      GraphQLQuery<ListBusinessHourTastiesQuery>
    >(
      graphqlOperation(queries.listBusinessHourTasties, {
        ...variables,
        filter: { _deleted: { ne: true } },
      } as ListBusinessHourTastiesQueryVariables)
    );
    console.debug(
      "[BUSINESS_HOURS_TASTY] fetchBusinessHours",
      response.data?.listBusinessHourTasties?.items.length
    );
    return response.data?.listBusinessHourTasties?.items as BusinessHourTasty[];
  } catch (error) {
    console.error("[BUSINESS_HOURS_TASTY] fetchBusinessHours", error);
    return [];
  }
}

export async function create(input: CreateBusinessHourTastyInput) {
  try {
    const response = await API.graphql<
      GraphQLQuery<CreateBusinessHourTastyMutation>
    >(
      graphqlOperation(mutations.createBusinessHourTasty, {
        input: input,
      } as CreateBusinessHourTastyMutationVariables)
    );
    console.debug(
      "[BUSINESS_HOURS_TASTY] createBusinessHourTasty",
      response.data?.createBusinessHourTasty
    );
  } catch (error) {
    console.error("[BUSINESS_HOURS_TASTY] createBusinessHourTasty", error);
    return null;
  }
}

export async function update(input: UpdateBusinessHourTastyInput) {
  console.debug("[BUSINESS_HOURS_TASTY] input", input);
  try {
    const response = await API.graphql<
      GraphQLQuery<UpdateBusinessHourTastyMutation>
    >({
      query: mutations.updateBusinessHourTasty,
      variables: { input: removeTypename(input) },
    });

    console.debug(
      "[BUSINESS_HOURS_TASTY] updateBusinessHourTasty",
      response.data?.updateBusinessHourTasty
    );
  } catch (error) {
    console.error("[BUSINESS_HOURS_TASTY] updateBusinessHourTasty", error);
    return null;
  }
}

export async function deleteByID(id: string) {
  try {
    const response = await API.graphql<
      GraphQLQuery<DeleteBusinessHourTastyMutation>
    >(
      graphqlOperation(mutations.deleteBusinessHourTasty, {
        input: { id: id },
      } as DeleteBusinessHourTastyMutationVariables)
    );
    console.debug(
      "[BUSINESS_HOURS_TASTY] deleteBusinessHourTastyByID",
      response.data?.deleteBusinessHourTasty
    );
  } catch (error) {
    console.error("[BUSINESS_HOURS_TASTY] deleteBusinessHourTastyByID", error);
    return null;
  }
}
