import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Box, Button, Divider, Input, Typography } from "@mui/material";

import { DriverApi } from "../../api-client";
import { TransportationModes } from "../../API";
import { message } from "antd";

type DriverAddProps = {
  onCreateDriverSuccess: () => void;
};

const DriverAdd: React.FC<DriverAddProps> = ({
  onCreateDriverSuccess,
}: DriverAddProps) => {
  const [name, setName] = useState("Driver");
  const [email, setEmail] = useState("@tasty-delivery.de");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (name && password && email) {
      try {
        const signUpData = await Auth.signUp({
          username: email,
          password: password,
          attributes: {
            email: email,
            "custom:type": "DRIVER",
          },
        });

        console.log("User signed up successfully");
        await DriverApi.create({
          name: name,
          sub: signUpData.userSub,
          email: email,
          password: password,
          transportationMode: TransportationModes.DRIVING,
        });

        setName("driver-");
        setEmail("@tasty-delivery.de");
        setPassword("");
        onCreateDriverSuccess();
        message.success("Fahrer erfolgreich hinzugefügt");
      } catch (error) {
        console.error("Error signing up user:", error);
        // @ts-ignore
        message.error(error?.message);
      }
    } else {
      message.error("Bitte füllen Sie alle Felder aus");
    }
  };

  return (
    <Box flexDirection={"column"} display={"flex"} width={360} margin={2}>
      <Input
        style={{ padding: 2, marginTop: 2 }}
        type="text"
        placeholder="Name"
        value={name}
        onChange={(e) => {
          setName(e.target.value);
          setEmail(
            e.target.value.replace("Driver", "Driver-").toLowerCase() +
              "@tasty-delivery.de"
          );
          setPassword(
            (
              e.target.value.substring(0, 1).toUpperCase() +
              e.target.value.substring(1) +
              "24!?"
            ).replace("Driver", "Tasty")
          );
        }}
      />
      <Input
        disabled
        style={{ padding: 2, marginTop: 2 }}
        type="email"
        placeholder="Email"
        value={email}
      />
      <Input
        style={{ padding: 2, marginTop: 2 }}
        // type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button
        style={{ marginTop: 8 }}
        variant="contained"
        onClick={handleSubmit}
      >
        Fahrer hinzufügen
      </Button>
    </Box>
  );
};

export default DriverAdd;
