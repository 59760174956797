import React, { useMemo } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import moment from "moment";

import { Driver } from "../../API";

const center = {
  lat: 47.66033,
  lng: 9.17582,
};

export default function DriversMap({
  drivers,
  selectedDriver,
  setSelectedDriver,
}: {
  drivers: Driver[];
  selectedDriver: Driver | null;
  setSelectedDriver: (driver: Driver | null) => void;
}) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyC9Th0xOzOvhhCUSLz6uttvsbNaUZPefd8",
  });

  const [coordinates, setCoordinates] = React.useState<{
    lat: number;
    lng: number;
  }>(center);

  const currentLocation = useMemo(() => coordinates, [drivers]);

  if (!isLoaded) {
    return null;
  }

  return (
    <GoogleMap
      center={selectedDriver || (currentLocation as any)}
      zoom={selectedDriver ? 16 : 12}
      mapContainerStyle={{ height: "100vh", width: "100vh" }}
    >
      {drivers.map((driver) => {
        if (!driver.lat || !driver.lng) {
          return null;
        }

        return (
          <Marker
            key={driver.id}
            position={{ lat: driver.lat, lng: driver.lng }}
            icon={{
              url: require("../../assets/driver-marker-icon.png"),
              anchor: new google.maps.Point(17, 46),
              scaledSize: new google.maps.Size(50, 50),
            }}
            animation={google.maps.Animation.DROP}
            label={{
              text: driver.name,
              color: "red",
              fontWeight: "bold",
              fontSize: selectedDriver ? "24px" : "0",
            }}
            onClick={(e) => {
              setSelectedDriver(driver);

              if (e.latLng?.lat() && e.latLng?.lng()) {
                setCoordinates({
                  lat: e.latLng?.lat(),
                  lng: e.latLng?.lng(),
                });
              }
            }}
          />
        );
      })}
    </GoogleMap>
  );
}
