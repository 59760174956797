import { GraphQLQuery } from "@aws-amplify/api";
import { API, graphqlOperation } from "aws-amplify";
import {
  GetOrderDishQuery,
  GetOrderDishQueryVariables,
  ListOrderDishesByOrderIDQuery,
  ListOrderDishesByOrderIDQueryVariables,
  OrderDish,
} from "../API";
import { getOrderDish, listOrderDishesByOrderID } from "../graphql/queries";

export async function queryItem(variables: GetOrderDishQueryVariables) {
  const payload = await API.graphql<GraphQLQuery<GetOrderDishQuery>>(
    graphqlOperation(getOrderDish, variables ? variables : {})
  );
  return payload.data?.getOrderDish as OrderDish;
}

export async function queryListByOrderID(
  variables: ListOrderDishesByOrderIDQueryVariables
) {
  const payload = await API.graphql<
    GraphQLQuery<ListOrderDishesByOrderIDQuery>
  >(graphqlOperation(listOrderDishesByOrderID, variables ? variables : {}));
  return payload.data?.listOrderDishesByOrderID?.items as OrderDish[];
}
