import { GraphQLQuery, GraphQLSubscription } from "@aws-amplify/api";
import { API, graphqlOperation } from "aws-amplify";
import { message } from "antd";

import {
  AppConfig,
  CreateAppConfigInput,
  CreateAppConfigMutation,
  CreateAppConfigMutationVariables,
  GetAppConfigQuery,
  GetAppConfigQueryVariables,
  ListAppConfigsQuery,
  ListAppConfigsQueryVariables,
  OnUpdateAppConfigSubscription,
  OnUpdateAppConfigSubscriptionVariables,
  UpdateAppConfigInput,
  UpdateAppConfigMutation,
} from "../API";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import * as subscriptions from "../graphql/subscriptions";

export async function getAppConfigByID(variables: GetAppConfigQueryVariables) {
  const payload = await API.graphql<GraphQLQuery<GetAppConfigQuery>>(
    graphqlOperation(queries.getAppConfig, variables ? variables : {})
  );
  console.debug("[APPCONFIG] getAppConfigByID", payload.data?.getAppConfig);
  return payload.data?.getAppConfig as AppConfig;
}

export async function getAppConfig() {
  try {
    const response = await API.graphql<GraphQLQuery<ListAppConfigsQuery>>(
      graphqlOperation(queries.listAppConfigs, {
        filter: { _deleted: { ne: true } },
      } as ListAppConfigsQueryVariables)
    );
    console.debug(
      "[APPCONFIG] getAppConfig",
      response.data?.listAppConfigs?.items.length
    );
    return response.data?.listAppConfigs?.items[0] as AppConfig;
  } catch (error: any) {
    console.error("[APPCONFIG] getAppConfig", error);
    message.error(
      error.errors && error.errors.length > 0
        ? error.errors[0].message
        : "Error getting app config"
    );
    return null;
  }
}

export async function createItem(input: CreateAppConfigInput) {
  const payload = await API.graphql<GraphQLQuery<CreateAppConfigMutation>>({
    query: mutations.createAppConfig,
    variables: { input } as CreateAppConfigMutationVariables,
  });
  return payload.data?.createAppConfig as AppConfig;
}

export async function updateItem(input: UpdateAppConfigInput) {
  const original = await getAppConfigByID({ id: input.id });
  const payload = await API.graphql<GraphQLQuery<UpdateAppConfigMutation>>({
    query: mutations.updateAppConfig,
    variables: { input: { ...input, _version: original?._version } },
  });
  return payload.data?.updateAppConfig as AppConfig;
}

export const observeAppConfig = (
  variables: OnUpdateAppConfigSubscriptionVariables
) =>
  API.graphql<GraphQLSubscription<OnUpdateAppConfigSubscription>>({
    query: subscriptions.onUpdateAppConfig,
    variables,
  });
