// Create a context
import React, { createContext, ReactElement, useEffect, useState } from "react";

import { Auth } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";

import { AppConfig, Restaurant } from "../API";
import { getAppConfig } from "../api-client/app-config-api";
import { getRestaurants } from "../api-client/restaurant-api";

export interface IAppContext {
  restaurants: Restaurant[] | null;
  setRestaurants: (restaurants: Restaurant[] | null) => void;
  sub?: string;
  loading: boolean;
  authUser: CognitoUser | any;
  appConfig: AppConfig | null;
}

const AppContext: React.Context<{}> = createContext<IAppContext | {}>({});

const initialState = {
  appConfig: {
    customerFee: 1,
    restaurantFee: 1,
  } as AppConfig,
};

const AppContextProvider = ({ children }: { children: ReactElement }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [authUser, setAuthUser] = useState<CognitoUser | any>();
  const [restaurants, setRestaurants] = useState<Restaurant[] | null>(null);
  const [appConfig, setAppConfig] = useState<AppConfig | null>(
    initialState.appConfig
  );

  const sub = authUser?.attributes?.sub;

  useEffect(() => {
    Auth.currentAuthenticatedUser({ bypassCache: true }).then((u) => {
      const whitelist = [
        "admin@tasty-delivery.de",
        "grimm@tasty-delivery.de",
        "malek@tasty-delivery.de",
      ];

      if (whitelist.indexOf(u.attributes.email) > -1) {
        //In the array!
        setAuthUser(u);
      } else {
        //Not in the array
        alert("Du bist kein Admin. Tschüssssss");
        Auth.signOut();
      }
    });
  }, []);

  async function fetchData() {
    setLoading(true);
    // fetch Restaurant and filter by adminSub

    const _restaurants = await getRestaurants();
    setRestaurants(_restaurants);

    const _appConfig = await getAppConfig();
    setAppConfig(_appConfig);

    setTimeout(() => setLoading(false), 500);
  }

  useEffect(() => {
    if (!sub) {
      return;
    }

    fetchData();
  }, [sub]);

  return (
    <AppContext.Provider
      value={{
        restaurants,
        setRestaurants,
        sub,
        loading,
        authUser,
        appConfig,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = (): IAppContext =>
  React.useContext(AppContext) as IAppContext;

export { AppContext, AppContextProvider };
