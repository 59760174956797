import { StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment";

import formatPrice from "../../utils/formatPrice";
import { RestaurantInvoices } from "../../models";

export default function InvoiceItem(invoiceItem: RestaurantInvoices) {
  return (
    <View
      style={{
        borderBottom: "0.5px solid lightgrey",
        marginVertical: 4,
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <View>
        <Text style={[styles.text, { fontSize: 12 }]}>
          {moment(invoiceItem.invoiceCreatedAt).format("DD.MM.YYYY")}
        </Text>
        <Text style={[styles.text, { fontSize: 12 }]}>
          Rechnungsnummer:{" "}
          {invoiceItem.invoiceID?.substring(0, 4).toUpperCase()}
        </Text>
        <Text style={[styles.text, { fontSize: 12 }]}>
          Auftragsnummer: {invoiceItem.orderID?.substring(0, 4).toUpperCase()}
        </Text>
        <Text style={[styles.text, { fontSize: 12 }]}>
          PaymentID: {invoiceItem.paymentIntentID}
        </Text>
        <Text style={[styles.text, { fontSize: 12 }]}>
          {formatPrice(invoiceItem.invoiceTotalNet!)}
        </Text>
      </View>

      {invoiceItem.hasRefund && (
        <View>
          <Text style={[styles.text, { fontSize: 12 }]}>
            Rückerstattung: {formatPrice(invoiceItem?.refundAmount || 0)}
          </Text>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  text: {
    fontSize: 14,
    fontFamily: "Amble",
  },
});
