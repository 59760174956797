import { GraphQLQuery } from "@aws-amplify/api";
import { API, graphqlOperation } from "aws-amplify";

import * as queries from "../graphql/queries";

import {
  Dish,
  ListDishesQuery,
  ListDishesQueryVariables,
  GetDishQuery,
  GetDishQueryVariables,
} from "../API";

export async function fetchItem(variables: GetDishQueryVariables) {
  try {
    const response = await API.graphql<GraphQLQuery<GetDishQuery>>(
      graphqlOperation(queries.getDish, variables as GetDishQueryVariables)
    );
    console.debug("[DISH] fetchItem ", response.data?.getDish);
    return response.data?.getDish as Dish;
  } catch (error) {
    console.error("[DISH] fetchItem", error);
    return null;
  }
}

export async function fetchList(variables: ListDishesQueryVariables) {
  try {
    const response = await API.graphql<GraphQLQuery<ListDishesQuery>>(
      graphqlOperation(queries.listDishes, {
        ...variables,
        filter: { _deleted: { ne: true } },
      } as ListDishesQueryVariables)
    );
    console.debug("[DISH] fetchList", response.data?.listDishes?.items.length);
    return response.data?.listDishes?.items as Dish[];
  } catch (error) {
    console.error("[DISH] fetchList", error);
    return [];
  }
}
