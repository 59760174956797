import React, { useEffect, useRef, useState } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { Card, Select, message } from "antd";
import { Loader } from "@aws-amplify/ui-react";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";

import { API } from "aws-amplify";
import { GraphQLQuery, GraphQLResult } from "@aws-amplify/api";

import {
  ListOrdersQuery,
  ListOrdersQueryVariables,
  Order,
  OrderDish,
  OrderStatus,
  User,
} from "../../API";
import { listOrders } from "../../graphql/queries";
import { OrderApi, OrderDishApi, UserApi } from "../../api-client";
import { useAppContext } from "../../context/app-context";
import DeliveryIcon from "../../components/delivery-icon";
import formatPrice from "../../utils/formatPrice";
import OrderDishListItem from "./order-dish-list-item";
import renderOrderStatus from "../../utils/renderOrderStatus";

const days = 2;
const start = moment().subtract(days, "day").startOf("day").toISOString();

export default function Orders() {
  const throttling = useRef(false);
  const { t } = useTranslation();

  const { sub, restaurants } = useAppContext();

  const [orders, setOrders] = React.useState<Order[]>([]);

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleChange =
    (order: Order) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? order.id : false);
    };

  async function fetchOrders(loadingEnabled: boolean = true) {
    loadingEnabled && setLoading(true);

    let listOrderItems: Order[] = [];
    let listOrderDishesResponse: GraphQLResult<GraphQLQuery<ListOrdersQuery>> =
      {};
    let variables = {
      limit: 10000,
      filter: {
        createdAt: { gt: start },
        status: { ne: OrderStatus.PENDING },
        _deleted: { ne: true },
      },
    } as ListOrdersQueryVariables;

    console.log(`🛫[QUERY LIST ORDER START]`);

    listOrderDishesResponse = await API.graphql<GraphQLQuery<ListOrdersQuery>>({
      query: listOrders,
      variables: variables,
    });

    listOrderItems = [
      ...(listOrderItems || []),
      ...(listOrderDishesResponse?.data?.listOrders?.items || []),
    ] as Order[];

    while (listOrderDishesResponse?.data?.listOrders?.nextToken) {
      console.log(`➿[QUERY LIST ORDER LOOPING START]`);

      variables.nextToken = listOrderDishesResponse.data?.listOrders?.nextToken;

      listOrderDishesResponse = await API.graphql<
        GraphQLQuery<ListOrdersQuery>
      >({
        query: listOrders,
        variables: variables,
      });

      listOrderItems = [
        ...listOrderItems,
        ...(listOrderDishesResponse?.data?.listOrders?.items || []),
      ] as Order[];

      console.log(
        `🍀[QUERY LIST ORDER LOOPING LOOPING SUCCESS]`,
        listOrderDishesResponse?.data?.listOrders?.items.length
      );
    }

    updateData(listOrderItems);

    setLoading(false);
  }

  /**
   * Observe Model Order
   */
  useEffect(() => {
    if (!sub) {
      return;
    }

    // TODO: OrderApi.fetchList({filter: {createdAt: {gt: SOMEDATAE}  }})
    fetchOrders();

    const subscribeFilter = { filter: { status: { ne: OrderStatus.PENDING } } };

    const updateSubscription = OrderApi.updateSubscription(
      subscribeFilter
    ).subscribe({
      next: ({ provider, value }) => {
        /*   console.info('OrderApi received UPDATE event', {
          provider: JSON.stringify(provider),
          value: JSON.stringify(value),
        }); */

        const updatedOrder = value.data?.onUpdateOrder;
        console.info("[OBSERVE ORDER UPDATE]: ", updatedOrder?.id);

        if (!!updatedOrder?.id) {
          if (throttling.current) {
            return;
          }

          throttling.current = true;
          setTimeout(async () => {
            throttling.current = false;

            await fetchOrders(false);

            message.info(
              "Auftrag #" +
                updatedOrder.id.substring(0, 4).toUpperCase() +
                " wurde aktualisiert..."
            );
          }, 350);
        }
      },
      error: (error) => console.error(error),
    });

    return () => {
      updateSubscription.unsubscribe();
    };
  }, [sub]);

  const updateData = (items: Order[]) => {
    const sortedByCreationDate = items
      .sort(
        // @ts-ignore
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      )
      .reverse();
    setOrders(sortedByCreationDate);
  };

  const getColor = (createdAt: string): string => {
    if (moment().diff(moment(createdAt), "minutes") < 15) {
      return "green";
    } else if (
      moment().diff(moment(createdAt), "minutes") > 15 &&
      moment().diff(moment(createdAt), "minutes") < 30
    ) {
      return "orange";
    } else {
      return "red";
    }
  };

  return (
    <Card title={t("app.orders.title")}>
      <Box minHeight={40} maxHeight={40}>
        {loading && (
          <Box
            flex={"row"}
            display="flex"
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Loader size="large" />
            <Typography>Die letzten {days} Tage werden abgerufen</Typography>
          </Box>
        )}
      </Box>

      {orders.map((order) => {
        return (
          <Accordion
            key={order.id}
            TransitionProps={{ unmountOnExit: true }}
            expanded={expanded === order.id}
            onChange={handleChange(order)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={order.id + "-content"}
              id={order.id + "-header"}
            >
              <Box sx={{ width: "50%" }}>
                <Typography
                  sx={{
                    fontSize: 22,
                    fontWeight: "bold",
                  }}
                >
                  #{order.id.substring(0, 4).toUpperCase()}
                </Typography>

                {renderOrderStatus(order.status)}

                {order.createdAt && (
                  <Typography
                    color={getColor(order.createdAt)}
                    sx={{ marginTop: 1 }}
                  >
                    {moment(order.createdAt).format("DD.MM.YY - HH:mm") +
                      " Uhr"}
                  </Typography>
                )}

                <Typography>{formatPrice(order.total)}</Typography>

                <DeliveryIcon
                  order={order}
                  restaurant={restaurants?.find(
                    (rest) => rest?.id === order.orderRestaurantId
                  )}
                />

                {order.note && (
                  <Box style={{ marginTop: 8 }}>
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontWeight: "bold",
                        color: "orange",
                      }}
                    >
                      Anmerkung:
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: 16,
                        fontWeight: "bold",
                        color: "orange",
                      }}
                    >
                      {order.note}
                    </Typography>
                  </Box>
                )}
              </Box>

              <Box sx={{ flexShrink: 0 }}>
                <Typography
                  sx={{
                    fontSize: 22,
                    fontWeight: "bold",
                  }}
                >
                  {
                    restaurants?.find(
                      (rest) => rest?.id === order.orderRestaurantId
                    )?.name
                  }
                </Typography>

                <DriverComponent driver={order.Driver} label="Fahrer: " />
                <UserComponent userID={order.userID} />
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              {order && (
                <Box>
                  <SelectOrderStatusComponent order={order} />

                  <Typography fontWeight={"bold"} fontSize={22}>
                    Details
                  </Typography>

                  {expanded === order.id && (
                    <OrderDishesComponent orderID={order.id} />
                  )}
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Card>
  );
}

function SelectOrderStatusComponent({ order }: { order: Order }) {
  const updateOrderStatus = async (newStatus: OrderStatus, order: Order) => {
    //setLoading(true);
    try {
      if (!order?.id) {
        return alert("No order id");
      }

      const original = await OrderApi.fetchItem({ id: order?.id });

      if (!original?.id) {
        return alert("No order found");
      }

      await OrderApi.update({
        id: original.id,
        _version: original._version,
        status: newStatus,
      });
    } catch (error) {
      console.error("###### ERROR | updateOrderStatus", error);
    } finally {
      setTimeout(() => {
        //setLoading(false);
      }, 500);
    }
  };

  return (
    <Select
      style={{ width: "100%", marginBottom: 16 }}
      value={order.status}
      options={Object.keys(OrderStatus).map((key) => {
        return {
          value: key,
          label: key,
        };
      })}
      onChange={(v) => updateOrderStatus(v as OrderStatus, order)}
    />
  );
}

function OrderDishesComponent({ orderID }: { orderID: Order["id"] }) {
  const [loading, setLoading] = useState<boolean>(true);
  const [orderDishes, setOrderDishes] = useState<OrderDish[]>([]);

  useEffect(() => {
    if (!orderID) {
      return;
    }

    OrderDishApi.queryListByOrderID({ orderID, limit: 100 }).then(
      setOrderDishes
    );
  }, [orderID]);

  if (!orderDishes) {
    return <Loader />;
  }

  return (
    <>
      {loading && <Loader size="large" />}
      {orderDishes?.map((orderDish) => {
        return (
          <OrderDishListItem
            key={orderDish?.id}
            orderDish={orderDish}
            onCompleted={() => setLoading(false)}
          />
        );
      })}
    </>
  );
}

function DriverComponent({
  driver,
  label,
}: {
  driver: Order["Driver"];
  label?: string;
}) {
  return (
    <Typography>
      {label ? label : ""}
      {driver?.name || <strong>Noch nicht angesetzt...</strong>}
    </Typography>
  );
}

function UserComponent({ userID }: { userID: Order["userID"] }) {
  const { ref, inView } = useInView();

  const [user, setUser] = React.useState<User | null>(null);

  async function loadUser() {
    console.info("🔍[USER] loadUser", userID);
    const theUser = await UserApi.fetchUserByID({ id: userID });
    setUser(theUser);
  }

  useEffect(() => {
    if (inView) {
      loadUser();
    }
  }, [inView]);

  return (
    <div ref={ref}>
      {user && (
        <>
          <Typography>
            {<strong>{user?.id || "Nicht gefunden..."}</strong>}
          </Typography>
          <Typography>
            {<strong>{user?.name || "Nicht gefunden..."}</strong>}
          </Typography>
          <Typography>
            {user?.address || user?.addressData?.formattedAddress}
          </Typography>
        </>
      )}
    </div>
  );
}
