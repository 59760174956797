import {
  GraphQLResult,
  GraphQLQuery,
  GraphQLSubscription,
} from "@aws-amplify/api";
import { API, graphqlOperation } from "aws-amplify";

import * as queries from "../graphql/queries";

import {
  CreateDriverInput,
  CreateDriverMutation,
  CreateDriverMutationVariables,
  DeleteDriverMutation,
  DeleteDriverMutationVariables,
  Driver,
  GetDriverQuery,
  GetDriverQueryVariables,
  ListDriversQuery,
  ListDriversQueryVariables,
  OnUpdateDriverSubscription,
  OnUpdateDriverSubscriptionVariables,
} from "../API";
import { createDriver, deleteDriver } from "../graphql/mutations";
import { onUpdateDriver } from "../graphql/subscriptions";

export async function create(input: CreateDriverInput) {
  try {
    const response = await API.graphql<GraphQLQuery<CreateDriverMutation>>(
      graphqlOperation(createDriver, {
        input: input,
      } as CreateDriverMutationVariables)
    );
    console.debug("[DRIVER] createDriver", response.data?.createDriver);
  } catch (error) {
    console.error("[DRIVER] createDriver", error);
    return null;
  }
}

export async function deleteByID(id: string, _version: number = 0) {
  try {
    const response = await API.graphql<GraphQLQuery<DeleteDriverMutation>>(
      graphqlOperation(deleteDriver, {
        input: { id: id, _version },
      } as DeleteDriverMutationVariables)
    );
    console.debug("[DRIVER] deleteDriverByID", response.data?.deleteDriver);
  } catch (error) {
    console.error("[DRIVER] deleteDriverByID", error);
    return null;
  }
}

export async function fetchItem(variables: GetDriverQueryVariables) {
  try {
    const response = await API.graphql<GraphQLQuery<GetDriverQuery>>(
      graphqlOperation(queries.getDriver, variables as GetDriverQueryVariables)
    );
    console.debug("[DRIVER] fetchItem ", response.data?.getDriver);
    return response.data?.getDriver as Driver;
  } catch (error) {
    console.error("[DRIVER] fetchItem", error);
    return null;
  }
}

export async function fetchList(variables: ListDriversQueryVariables) {
  let allItems: Driver[] = [];
  let nextToken: string | null = null;

  try {
    console.debug("[DRIVER] START", variables);

    do {
      const payload: GraphQLResult<GraphQLQuery<ListDriversQuery>> =
        await API.graphql<GraphQLQuery<ListDriversQuery>>(
          graphqlOperation(
            queries.listDrivers,
            variables ? { ...variables, nextToken } : {}
          )
        );

      console.debug(
        "[DRIVER] fetchList",
        payload.data?.listDrivers?.items.length
      );

      const items = payload.data?.listDrivers?.items || [];
      allItems = allItems.concat(items as Driver[]);
      nextToken = payload.data?.listDrivers?.nextToken || null;
    } while (nextToken);

    return allItems;
  } catch (error) {
    console.error("[DRIVER] fetchList", error);
    return [];
  }
}

export function updateSubscription(
  variables?: OnUpdateDriverSubscriptionVariables
) {
  return API.graphql<GraphQLSubscription<OnUpdateDriverSubscription>>({
    query: onUpdateDriver,
    variables,
  });
}
