import React from "react";

import { Card } from "antd";
import { useTranslation } from "react-i18next";

import AdminConfigForm from "./admin-config-form";

export default function Settings() {
  const { t } = useTranslation();

  return (
    <Card title={t("app.side-menu.settings")}>
      <AdminConfigForm
        clearOnSuccess={false}
        onCancel={() => console.log("onCancel")}
        onChange={(data) => console.log("onChange", data)}
        onError={(data) => console.log("onError", data)}
        onSubmit={(data) => console.log("onSubmit", data)}
        onSuccess={(data) => console.log("onSuccess", data)}
        onValidate={(data) => console.log("onValidate", data)}
      />
    </Card>
  );
}
