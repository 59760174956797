import { useEffect, useLayoutEffect, useState } from "react";

import { Box, Skeleton, Typography } from "@mui/material";

import { DishApi } from "../../api-client";
import { Dish, OrderDish } from "../../API";
import parseSelectedDishOptionsJSONtoArray from "../../utils/parseSelectedDishOptionsJSONtoArray";

export default function OrderDishListItem({
  orderDish,
  onCompleted,
}: {
  orderDish: OrderDish;
  onCompleted: () => void;
}) {
  const [dish, setDish] = useState<Dish | null>();
  const [error, setError] = useState<string | null>(null);

  useLayoutEffect(() => {
    if (orderDish?.orderDishDishId) {
      setError(null);

      DishApi.fetchItem({ id: orderDish?.orderDishDishId! }).then(setDish);

      onCompleted();

      return;
    }

    setError("Das Gericht kann nicht geladen werden...");

    return;
  }, [orderDish?.orderDishDishId]);

  const optionsList = parseSelectedDishOptionsJSONtoArray(
    orderDish?.selectedDishOptionsJSON
  );

  if (!dish) {
    return (
      <Skeleton
        variant="rectangular"
        height={60}
        style={{
          marginTop: 4,
          backgroundColor: "rgba(0,0,0,0.1)",
          padding: 16,
          borderRadius: 8,
        }}
      />
    );
  }

  return (
    <Box
      style={{
        marginTop: 4,
        backgroundColor: "rgba(0,0,0,0.1)",
        padding: 16,
        borderRadius: 8,
      }}
    >
      {error ? (
        <Typography variant="caption" color="red">
          {error}
        </Typography>
      ) : (
        <Typography fontWeight="900">{dish?.name}</Typography>
      )}

      {optionsList &&
        optionsList.length > 0 &&
        optionsList.map((option) => {
          return (
            <div
              key={option.id}
              style={{
                padding: 4,
                borderBottom: "0.5px solid lightgrey",
              }}
            >
              <Typography>{option.title}</Typography>
              {option.items?.map((item) => {
                return <Typography key={item?.id}>- {item?.title}</Typography>;
              })}
            </div>
          );
        })}
    </Box>
  );
}
