import {
  Box,
  Button,
  ListItemSecondaryAction,
  Typography,
} from "@mui/material";
import { message } from "antd";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import moment from "moment";

import { Driver } from "../../API";
import { DriverApi } from "../../api-client";

export default function DriversList({
  data,
  onReload,
}: {
  data: Driver[];
  onReload: () => void;
}) {
  const onClickDelete = async (driver: Driver) => {
    await DriverApi.deleteByID(driver.id, driver._version);

    /**
     * DELETE COGNITO USER
     */

    onReload();

    message.success(
      "Fahrer erfolgreich gelöscht. Bitte nun über das Cognito Dashboard den Benutzer: " +
        driver.name +
        " mit der sub: " +
        driver.sub +
        " löschen.",
      10
    );
  };

  return (
    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
      {data.map((driver) => (
        <ListItem key={driver.id} alignItems="flex-start">
          <Box flexDirection={"column"} display="flex">
            <Typography variant="caption">{"Name: " + driver.name}</Typography>
            <Typography variant="caption">
              {"Email: " + driver.email}
            </Typography>
            <Typography variant="caption">
              {"Passwort: " + driver.password}
            </Typography>

            <Typography variant="caption">{"SUB: " + driver.sub}</Typography>

            <Typography variant="caption">
              {"Erstellt: " + moment(driver.createdAt).calendar()}
            </Typography>

            <Typography variant="caption">
              {"Zuletzt aktualisiert: " + moment(driver.updatedAt).calendar()}
            </Typography>
          </Box>

          <ListItemSecondaryAction>
            <Button
              variant="contained"
              color="error"
              onClick={() => onClickDelete(driver)}
            >
              Löschen
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
}
