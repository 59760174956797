import {
  GraphQLResult,
  GraphQLQuery,
  GraphQLSubscription,
} from "@aws-amplify/api";
import { API, graphqlOperation } from "aws-amplify";

import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import * as subscriptions from "../graphql/subscriptions";

import {
  Order,
  CreateOrderInput,
  CreateOrderMutation,
  CreateOrderMutationVariables,
  DeleteOrderMutation,
  DeleteOrderMutationVariables,
  ListOrdersQuery,
  ListOrdersQueryVariables,
  UpdateOrderInput,
  UpdateOrderMutation,
  UpdateOrderMutationVariables,
  GetOrderQuery,
  GetOrderQueryVariables,
  OnCreateOrderSubscriptionVariables,
  OnCreateOrderSubscription,
  OnUpdateOrderSubscriptionVariables,
  OnUpdateOrderSubscription,
} from "../API";

export async function fetchItem(variables: GetOrderQueryVariables) {
  try {
    const response = await API.graphql<GraphQLQuery<GetOrderQuery>>(
      graphqlOperation(queries.getOrder, variables as GetOrderQueryVariables)
    );
    console.debug("[ORDER] fetchItem ", response.data?.getOrder);
    return response.data?.getOrder as Order;
  } catch (error) {
    console.error("[ORDER] fetchItem", error);
    return null;
  }
}

export async function fetchList(variables: ListOrdersQueryVariables) {
  let allOrders: Order[] = [];
  let nextToken: string | null = null;

  do {
    console.log("nextToken: ", !!nextToken);
    const payload: GraphQLResult<GraphQLQuery<ListOrdersQuery>> =
      await API.graphql<GraphQLQuery<ListOrdersQuery>>(
        graphqlOperation(
          queries.listOrders,
          variables ? { ...variables, nextToken } : {}
        )
      );

    const orders = payload.data?.listOrders?.items as Order[];
    console.log("orders: ", orders?.length);
    if (orders) {
      allOrders = allOrders.concat(orders);
    }

    nextToken = payload.data?.listOrders?.nextToken || null;
  } while (nextToken);

  return allOrders;
}

export async function create(input: CreateOrderInput) {
  try {
    const response = await API.graphql<GraphQLQuery<CreateOrderMutation>>(
      graphqlOperation(mutations.createOrder, {
        input: input,
      } as CreateOrderMutationVariables)
    );
    console.debug("[ORDER] createOrder", response.data?.createOrder);
  } catch (error) {
    console.error("[ORDER] createOrder", error);
    return null;
  }
}

export async function update(input: UpdateOrderInput) {
  try {
    const response = await API.graphql<GraphQLQuery<UpdateOrderMutation>>(
      graphqlOperation(mutations.updateOrder, {
        input: input,
      } as UpdateOrderMutationVariables)
    );
    console.debug("[ORDER] updateOrder", response.data?.updateOrder);
  } catch (error) {
    console.error("[ORDER] updateOrder", error);
    return null;
  }
}

export async function deleteByID(id: string) {
  try {
    const response = await API.graphql<GraphQLQuery<DeleteOrderMutation>>(
      graphqlOperation(mutations.deleteOrder, {
        input: { id: id },
      } as DeleteOrderMutationVariables)
    );
    console.debug("[ORDER] deleteOrderByID", response.data?.deleteOrder);
  } catch (error) {
    console.error("[ORDER] deleteOrderByID", error);
    return null;
  }
}

export function createSubscription(
  variables?: OnCreateOrderSubscriptionVariables
) {
  return API.graphql<GraphQLSubscription<OnCreateOrderSubscription>>({
    query: subscriptions.onCreateOrder,
    variables,
  });
}

export function updateSubscription(
  variables?: OnUpdateOrderSubscriptionVariables
) {
  return API.graphql<GraphQLSubscription<OnUpdateOrderSubscription>>({
    query: subscriptions.onUpdateOrder,
    variables,
  });
}
