export default function removeTypename(obj: any): any {
  if (obj === null || obj === undefined || typeof obj !== "object") {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => removeTypename(item));
  }

  const newObj: any = {};
  Object.keys(obj).forEach((key) => {
    if (key !== "__typename") {
      newObj[key] = removeTypename(obj[key]);
    }
  });
  return newObj;
}
