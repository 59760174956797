import { Route, Routes } from "react-router-dom";

import { useAppContext } from "../../context/app-context";
import Drivers from "../../screens/drivers";
import Orders from "../../screens/orders";
import Restaurants from "../../screens/restaurants";
import Settings from "../../screens/settings";
import RestaurantInvoices from "../../screens/restaurant-invoices";
import Invoices from "../../screens/invoices";

export default function AppRoutes() {
  const { loading } = useAppContext();

  if (loading) return <h1>Loading...</h1>; //<LoadingOverlay />;

  return (
    <Routes>
      <Route path={"/"} element={<Orders />} />
      <Route path={"drivers"} element={<Drivers />} />
      <Route path={"restaurants"} element={<Restaurants />} />
      <Route path={"restaurant-invoices"} element={<RestaurantInvoices />} />
      <Route path={"invoices"} element={<Invoices />} />
      <Route path={"settings"} element={<Settings />} />
    </Routes>
  );
}
