import React, { useEffect, useState } from "react";
import { isNaN } from "lodash";
import { Card, Divider, List } from "antd";
import { Loader } from "@aws-amplify/ui-react";
import {
  Box,
  Stack,
  styled,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { Restaurant, RestaurantStatus } from "../../API";
import { RestaurantApi } from "../../api-client";
import ToggleDeliveryProvider from "../../components/toggle-delivery-provider";

export default function Restaurants() {
  const [loading, setLoading] = useState<string | boolean>(false);
  const [restaurants, setRestaurants] = useState<Restaurant[]>([]);

  const fetchData = async (id?: string) => {
    setLoading(id ? id : true);
    RestaurantApi.getRestaurants()
      .then(setRestaurants)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const updateRestaurantListRanking = async (
    value: number,
    restaurant: Restaurant
  ) => {
    await RestaurantApi.updateRestaurantByID({
      id: restaurant.id,
      listRanking: value,
    });
    fetchData();
  };

  const onClickRestaurantStatus = async (id: string) => {
    setLoading(id);
    const original = await RestaurantApi.getRestaurantByID({ id });
    await RestaurantApi.updateRestaurantByID({
      id: id,
      status:
        original?.status === RestaurantStatus.ACTIVE
          ? RestaurantStatus.INACTIVE
          : RestaurantStatus.ACTIVE,
    });
    fetchData(id);
  };

  return (
    <Card
      title={"Tasty Restaurants (" + restaurants.length + ")"}
      loading={loading === true}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <List
          split
          dataSource={restaurants.sort((a, b) =>
            a.name > b.name ? 1 : b.name > a.name ? -1 : 0
          )}
          renderItem={(restaurant, index) => {
            return (
              <Box key={restaurant.id}>
                <Typography fontWeight={"bold"} variant="h5" textAlign={"left"}>
                  {restaurant.name}
                </Typography>

                <Box
                  borderColor={"black"}
                  borderRadius={2}
                  padding={3}
                  marginY={1}
                  style={{
                    backgroundColor: "#f0f0f0",
                  }}
                >
                  <Box
                    flexDirection={"row"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Box width="48%">
                      <Typography fontWeight={"bold"}>Adresse</Typography>
                      {restaurant.address.split(",").map((r, index) => (
                        <Typography key={index} fontWeight={"300"}>
                          {r}
                        </Typography>
                      ))}
                    </Box>

                    <Box width="48%">
                      <ToggleDeliveryProvider restaurant={restaurant} />
                    </Box>
                  </Box>

                  <Divider />

                  <Box
                    flexDirection={"row"}
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Box>
                      <Typography fontWeight={"bold"}>Rang</Typography>
                      <TextField
                        inputMode="numeric"
                        label="Ranking"
                        placeholder={restaurant.listRanking?.toString()}
                        type="number"
                        onChange={(e) => {
                          if (!isNaN(e.target.value))
                            updateRestaurantListRanking(
                              parseInt(e.target.value),
                              restaurant
                            );
                        }}
                      />
                    </Box>

                    <Box>
                      <Typography fontWeight={"bold"}>Status</Typography>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography
                          fontWeight={
                            RestaurantStatus.INACTIVE === restaurant.status
                              ? "bold"
                              : "normal"
                          }
                          textTransform={"uppercase"}
                          fontSize={
                            RestaurantStatus.INACTIVE === restaurant.status
                              ? 20
                              : 14
                          }
                        >
                          Inaktiv
                        </Typography>

                        {loading === restaurant.id ? (
                          <Loader />
                        ) : (
                          <AntSwitch
                            defaultChecked={
                              RestaurantStatus.ACTIVE === restaurant.status
                            }
                            inputProps={{ "aria-label": "ant design" }}
                            value={
                              RestaurantStatus.ACTIVE === restaurant.status
                            }
                            onChange={() =>
                              onClickRestaurantStatus(restaurant.id)
                            }
                          />
                        )}
                        <Typography
                          fontWeight={
                            RestaurantStatus.ACTIVE === restaurant.status
                              ? "bold"
                              : "normal"
                          }
                          textTransform={"uppercase"}
                          fontSize={
                            RestaurantStatus.ACTIVE === restaurant.status
                              ? 20
                              : 14
                          }
                        >
                          Aktiv
                        </Typography>
                      </Stack>
                    </Box>
                  </Box>
                </Box>
                <Divider />
              </Box>
            );
          }}
        />
      </div>
    </Card>
  );
}
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));
