import { OrderStatus } from "../models";
import { Tag } from "antd";
import _ from "lodash";

import i18n from "../i18n";

const renderOrderStatus = (orderStatus: OrderStatus | string) => {
  const statusToColor = {
    /**
     * Order statuses
     */
    [OrderStatus.ACCEPTED]: "purple",
    [OrderStatus.NEW]: "blue",
    [OrderStatus.COOKING]: "orange",
    [OrderStatus.READY_FOR_PICKUP]: "red",
    /**
     * History statuses
     */
    [OrderStatus.PICKED_UP]: "blue",
    [OrderStatus.COMPLETED]: "green",
    [OrderStatus.DECLINED_BY_RESTAURANT]: "red",
  };

  return (
    <Tag color={_.get(statusToColor, orderStatus, "black")}>
      {i18n.t(`app.order-status.${orderStatus}`)}
    </Tag>
  );
};

export default renderOrderStatus;
