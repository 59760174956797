/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import {
  Button,
  Flex,
  Grid,
  SwitchField,
  TextField,
} from "@aws-amplify/ui-react";
import { Card, TimePicker } from "antd";

import { Box } from "@mui/material";
import { isString } from "lodash";
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

import { AppConfig, UpdateAppConfigInput } from "../../API";
import { AppConfigApi } from "../../api-client";
import { fetchByPath, validateField } from "../../ui-components/utils";
import BusinessHours from "../../components/business-hours";

type EventValue<DateType> = DateType | null;
type RangeValue<DateType> = [EventValue<DateType>, EventValue<DateType>] | null;

type ModelFields = {
  customerFee?: AppConfig["customerFee"];
  deliveryFee?: AppConfig["deliveryFee"];
  restaurantFee?: AppConfig["restaurantFee"];
  minimumOrderValue?: AppConfig["minimumOrderValue"];
  openingHours?: AppConfig["openingHours"];
  maintenance?: AppConfig["maintenance"];
  taxRate?: AppConfig["taxRate"];
  banner?: AppConfig["banner"];
};

type Props = {
  clearOnSuccess: boolean;
  onSuccess: (modelFields: ModelFields) => any;
  onError: (modelFields: ModelFields, message: string) => any;
  onSubmit: (modelFields: ModelFields) => any;
  onCancel: () => any;
  onValidate: (modelFields: ModelFields) => any;
  onChange: (modelFields: ModelFields) => any;
};

export default function AdminConfigForm(props: Props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onCancel,
    onValidate,
    onChange,
    ...rest
  } = props;

  const [appConfig, setAppConfig] = React.useState<ModelFields | null>(null);

  console.log("STATE: ", appConfig?.maintenance);

  const [errors, setErrors] = React.useState({});

  const fetchData = () => {
    AppConfigApi.getAppConfig().then(setAppConfig);
  };

  React.useEffect(() => {
    fetchData();

    const subscription = AppConfigApi.observeAppConfig({}).subscribe({
      next: ({ value }) => {
        console.log("[OBSERVE APPCONFIG]", { value });

        if (value.data) {
          setAppConfig(value.data.onUpdateAppConfig as AppConfig);
        } else {
          fetchData();
        }
      },
      error: (error) => console.warn(error),
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const onChangeMaintance = (checked: boolean) => {
    const maintenance = checked
      ? {
          title: "Wartung",
          description: "Tasty befindet sich aktuell im Wartungsmodus.",
          type: "danger",
        }
      : null;

    const _appConfig = {
      ...appConfig,
      maintenance: maintenance,
    };

    console.log({ checked, _appConfig });

    setAppConfig(_appConfig as ModelFields);
  };
  const onChangeBannerVisible = (checked: boolean) => {
    const _appConfig = {
      ...appConfig,
      banner: {
        visible: !checked,
      },
    };

    setAppConfig(_appConfig as ModelFields);
  };

  const resetStateValues = () => {
    setAppConfig(appConfig || null);
    setErrors({});
  };

  const validations = {
    customerFee: [{ type: "Required" }],
    deliveryFee: [{ type: "Required" }],
    restaurantFee: [{ type: "Required" }],
    minimumOrderValue: [{ type: "Required" }],
    openingHours: [{ type: "Required" }],
    taxRate: [{ type: "Required" }],
  };

  const runValidationTasks = async (fieldName: string, value: number) => {
    // @ts-ignore
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };

  const _onSubmit = async (event: any) => {
    event.preventDefault();

    let modelFields = appConfig as ModelFields;

    const validationResponses: { hasError: boolean }[] = await Promise.all(
      Object.keys(validations).reduce((promises, fieldName) => {
        // @ts-ignore
        if (Array.isArray(modelFields[fieldName])) {
          promises.push(
            // @ts-ignore
            ...modelFields[fieldName].map((item) =>
              runValidationTasks(fieldName, item)
            )
          );
          return promises;
        }
        promises.push(
          // @ts-ignore
          runValidationTasks(fieldName, modelFields[fieldName])
        );
        return promises;
      }, [])
    );

    if (validationResponses.some((r) => r.hasError)) {
      return;
    }

    if (onSubmit) {
      modelFields = onSubmit(modelFields);
    }

    try {
      const originalAppConfig = await AppConfigApi.getAppConfig();

      if (!originalAppConfig && appConfig) {
        console.log("new App Config", { appConfig });
        const createdData = await AppConfigApi.createItem({
          customerFee: appConfig.customerFee,
          deliveryFee: appConfig.deliveryFee,
          restaurantFee: appConfig.restaurantFee,
          minimumOrderValue: appConfig.minimumOrderValue,
          openingHours: appConfig.openingHours,
          taxRate: appConfig.taxRate,
          maintenance: appConfig.maintenance,
        });
        setAppConfig(createdData);
      } else if (originalAppConfig && originalAppConfig.id) {
        console.log("update App Config", { appConfig });

        let _input: UpdateAppConfigInput = {
          id: originalAppConfig.id,
        };

        if (appConfig?.customerFee) {
          _input.customerFee = appConfig.customerFee;
        }
        if (appConfig?.deliveryFee) {
          _input.deliveryFee = appConfig.deliveryFee;
        }
        if (appConfig?.restaurantFee) {
          _input.restaurantFee = appConfig.restaurantFee;
        }
        if (appConfig?.minimumOrderValue) {
          _input.minimumOrderValue = appConfig.minimumOrderValue;
        }
        if (appConfig?.openingHours) {
          _input.openingHours = appConfig.openingHours;
        }
        if (appConfig?.taxRate) {
          _input.taxRate = appConfig.taxRate;
        }

        _input.maintenance = appConfig?.maintenance;

        const updatedData = await AppConfigApi.updateItem(_input);

        setAppConfig(updatedData);
      } else {
        throw new Error("No app config found");
      }

      if (onSuccess) {
        onSuccess(modelFields);
      }
      if (clearOnSuccess) {
        resetStateValues();
      }
    } catch (err: any) {
      if (onError) {
        onError(modelFields, err.message || err);
      }
    }
  };

  const onChangeOpeningHours = (
    time: RangeValue<Dayjs>,
    timeString: [string, string]
  ) => {
    setAppConfig({
      ...appConfig,
      openingHours: JSON.stringify({ time, timeString }),
    });
  };

  function OpeningHours() {
    const parsedOpeningHours = !!appConfig?.openingHours
      ? (JSON.parse(appConfig.openingHours) as {
          time: RangeValue<Dayjs>;
          timeString: [string, string];
        })
      : null;

    return (
      <>
        <TimePicker.RangePicker
          format={"HH:mm:ss"}
          value={
            [
              dayjs(parsedOpeningHours?.time?.[0]),
              dayjs(parsedOpeningHours?.time?.[1]),
            ]!
          }
          style={{ height: 42, marginTop: -5 }}
          placeholder={["Start", "Ende"]}
          onChange={onChangeOpeningHours}
          defaultOpenValue={dayjs("00:00:00", "HH:mm:ss")}
        />
      </>
    );
  }

  function FeeInputs() {
    return (
      <>
        <TextField
          value={appConfig?.customerFee || 0}
          label="Kundengebühr in %"
          isRequired={true}
          isReadOnly={false}
          type="number"
          step="any"
          onChange={(e) => {
            let value = Number(e.target.value);
            if (isNaN(value)) {
              setErrors((errors) => ({
                ...errors,
                customerFee: "Value must be a valid number",
              }));
              return;
            }
            if (onChange) {
              const result = onChange({
                ...appConfig,
                customerFee: value,
              });
              value = result?.customerFee ?? value;
            }
            // @ts-ignore
            if (errors.customerFee?.hasError) {
              runValidationTasks("customerFee", value);
            }
            setAppConfig({
              ...appConfig,
              customerFee: value,
            });
          }}
          onBlur={() =>
            appConfig?.customerFee &&
            runValidationTasks("customerFee", appConfig.customerFee)
          }
          // @ts-ignore
          errorMessage={errors.customerFee?.errorMessage}
          // @ts-ignore
          hasError={errors.customerFee?.hasError}
        />

        <TextField
          value={appConfig?.deliveryFee || 0}
          label="Liefergebühr in €"
          isRequired={true}
          isReadOnly={false}
          type="number"
          step="any"
          onChange={(e) => {
            let value = Number(e.target.value);
            if (isNaN(value)) {
              setErrors((errors) => ({
                ...errors,
                deliveryFee: "Value must be a valid number",
              }));
              return;
            }
            if (onChange) {
              const result = onChange({
                ...appConfig,
                deliveryFee: value,
              });
              value = result?.deliveryFee ?? value;
            }
            // @ts-ignore
            if (errors.deliveryFee?.hasError) {
              runValidationTasks("deliveryFee", value);
            }
            setAppConfig({
              ...appConfig,
              deliveryFee: value,
            });
          }}
          onBlur={() =>
            appConfig?.deliveryFee &&
            runValidationTasks("deliveryFee", appConfig.deliveryFee)
          }
          // @ts-ignore
          errorMessage={errors.deliveryFee?.errorMessage}
          // @ts-ignore
          hasError={errors.deliveryFee?.hasError}
          {...getOverrideProps(null, "deliveryFee")}
        />

        <TextField
          value={appConfig?.restaurantFee || 0}
          label="Restaurantgebühr in %"
          isRequired={true}
          isReadOnly={false}
          type="number"
          step="any"
          onChange={(e) => {
            let value = Number(e.target.value);
            if (isNaN(value)) {
              setErrors((errors) => ({
                ...errors,
                restaurantFee: "Value must be a valid number",
              }));
              return;
            }
            if (onChange) {
              const result = onChange({
                ...appConfig,
                restaurantFee: value,
              });
              value = result?.restaurantFee ?? value;
            }
            // @ts-ignore
            if (errors.restaurantFee?.hasError) {
              runValidationTasks("restaurantFee", value);
            }
            setAppConfig({
              ...appConfig,
              restaurantFee: value,
            });
          }}
          onBlur={() =>
            appConfig?.restaurantFee &&
            runValidationTasks("restaurantFee", appConfig.restaurantFee)
          }
          // @ts-ignore
          errorMessage={errors.restaurantFee?.errorMessage}
          // @ts-ignore
          hasError={errors.restaurantFee?.hasError}
        />

        <TextField
          value={appConfig?.minimumOrderValue || 0}
          label="Mindestbestellwert in €"
          isRequired={true}
          isReadOnly={false}
          type="number"
          step="any"
          onChange={(e) => {
            let value = Number(e.target.value);
            if (isNaN(value)) {
              setErrors((errors) => ({
                ...errors,
                minimumOrderValue: "Value must be a valid number",
              }));
              return;
            }
            if (onChange) {
              const result = onChange({
                ...appConfig,
                minimumOrderValue: value,
              });
              value = result?.minimumOrderValue ?? value;
            }
            // @ts-ignore
            if (errors.minimumOrderValue?.hasError) {
              runValidationTasks("minimumOrderValue", value);
            }
            setAppConfig({
              ...appConfig,
              minimumOrderValue: value,
            });
          }}
          onBlur={() =>
            appConfig?.minimumOrderValue &&
            runValidationTasks("minimumOrderValue", appConfig.minimumOrderValue)
          }
          // @ts-ignore
          errorMessage={errors.minimumOrderValue?.errorMessage}
          // @ts-ignore
          hasError={errors.minimumOrderValue?.hasError}
        />
      </>
    );
  }

  const BannerInput = () => {
    return (
      <Box>
        <SwitchField
          label="Aktivieren"
          isChecked={!!appConfig?.banner?.visible}
          onChange={(e) => {
            onChangeBannerVisible(e.target.checked);
          }}
        />
        <>
          <TextField
            disabled
            value={appConfig?.banner?.imageURL || ""}
            label="ImageURL (must be public)"
            isRequired={false}
            isReadOnly={false}
            type="url"
            step="any"
            onChange={(e) => {
              let value = e.target.value;
              if (!isString(value)) {
                setErrors((errors) => ({
                  ...errors,
                  banner: {
                    imageURL: "Value must be a valid url/string",
                  },
                }));
                return;
              }
              if (onChange) {
                const result = onChange({
                  ...appConfig,
                  banner: {
                    ...appConfig?.banner,
                    imageURL: value,
                  } as AppConfig["banner"],
                });
                value = result?.banner.imageURL ?? value;
              }
              /*      // @ts-ignore
            if (errors.banner?.imageURL?.hasError) {
              runValidationTasks("taxRate", value);
            } */
              setAppConfig({
                ...appConfig,
                banner: {
                  ...appConfig?.banner,
                  imageURL: value,
                } as AppConfig["banner"],
              });
            }}
            /*    onBlur={() =>
            appConfig?.banner?.imageURL &&
            runValidationTasks("banner", appConfig.banner?.imageURL)
          } */
            // @ts-ignore
            errorMessage={errors.banner?.imageURL?.errorMessage}
            // @ts-ignore
            hasError={errors.banner?.imageURL?.hasError}
          />
          <TextField
            disabled
            value={appConfig?.banner?.linkURL || ""}
            label="LinkURL (optional)"
            isRequired={false}
            isReadOnly={false}
            type="url"
            step="any"
            onChange={(e) => {
              let value = e.target.value;
              if (!isString(value)) {
                setErrors((errors) => ({
                  ...errors,
                  banner: {
                    linkURL: "Value must be a valid url/string",
                  },
                }));
                return;
              }
              if (onChange) {
                const result = onChange({
                  ...appConfig,
                  banner: {
                    ...appConfig?.banner,
                    linkURL: value,
                  } as AppConfig["banner"],
                });
                value = result?.banner.linkURL ?? value;
              }
              /*             // @ts-ignore
            if (errors.banner.linkURL?.hasError) {
              runValidationTasks("taxRate", value);
            } */
              setAppConfig({
                ...appConfig,
                banner: {
                  ...appConfig?.banner,
                  linkURL: value,
                } as AppConfig["banner"],
              });
            }}
            /*        onBlur={() =>
            appConfig?.taxRate &&
            runValidationTasks("taxRate", appConfig.taxRate)
          } */
            // @ts-ignore
            errorMessage={errors.banner?.linkURL?.errorMessage}
            // @ts-ignore
            hasError={errors.banner?.linkURL?.hasError}
          />
        </>
      </Box>
    );
  };

  const TaxInput = () => {
    return (
      <TextField
        value={appConfig?.taxRate || 0}
        label="MwSt. in %"
        isRequired={true}
        isReadOnly={false}
        type="number"
        step="any"
        onChange={(e) => {
          let value = Number(e.target.value);
          if (isNaN(value)) {
            setErrors((errors) => ({
              ...errors,
              taxRate: "Value must be a valid number",
            }));
            return;
          }
          if (onChange) {
            const result = onChange({
              ...appConfig,
              taxRate: value,
            });
            value = result?.taxRate ?? value;
          }
          // @ts-ignore
          if (errors.taxRate?.hasError) {
            runValidationTasks("taxRate", value);
          }
          setAppConfig({
            ...appConfig,
            taxRate: value,
          });
        }}
        onBlur={() =>
          appConfig?.taxRate && runValidationTasks("taxRate", appConfig.taxRate)
        }
        // @ts-ignore
        errorMessage={errors.taxRate?.errorMessage}
        // @ts-ignore
        hasError={errors.taxRate?.hasError}
      />
    );
  };

  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="0px"
      onSubmit={_onSubmit}
      {...rest}
    >
      <Card title="Öffnungszeiten (deprecated)">
        <OpeningHours />
      </Card>
      <Card title="Geschäftszeiten">
        <BusinessHours />
      </Card>
      <Card title="Gebühren">
        <FeeInputs />
      </Card>

      <Card title="Steuer">
        <TaxInput />
      </Card>

      <Card title="Banner">
        <BannerInput />
      </Card>
      <Card title="Wartung">
        <SwitchField
          label="Not Stop"
          isChecked={!!appConfig?.maintenance}
          onChange={(e) => {
            onChangeMaintance(e.target.checked);
          }}
        />
      </Card>

      <Flex justifyContent="space-between">
        <Button
          children="Reset"
          type="reset"
          onClick={resetStateValues}
          {...getOverrideProps(null, "ClearButton")}
        />
        <Flex {...getOverrideProps(null, "RightAlignCTASubFlex")}>
          <Button
            children="Cancel"
            type="button"
            onClick={() => {
              onCancel && onCancel();
            }}
            {...getOverrideProps(null, "CancelButton")}
          />
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            // @ts-ignore
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(null, "SubmitButton")}
          />
        </Flex>
      </Flex>
    </Grid>
  );
}
