import { Spin } from "antd";
import { Typography } from "@mui/material";

export default function LoadingOverlay({
  loading,
  text,
}: {
  loading: boolean;
  text?: string;
}) {
  if (!loading) {
    return null;
  }

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: "100vh",
        overflowX: "hidden" /* Disable horizontal scroll */,
        overflowY: "hidden" /* Disable horizontal scroll */,
        transition:
          "0.5s" /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */,
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(255,255,255,0.9)",
        zIndex: 1,
        flexDirection: "column",
      }}
    >
      <Spin size={"large"} />
      {text && (
        <Typography variant="caption" style={{ padding: 16 }}>
          {text.toUpperCase()}
        </Typography>
      )}
    </div>
  );
}
