import { GraphQLQuery } from "@aws-amplify/api";
import { API, graphqlOperation } from "aws-amplify";

import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";

import {
  CreateUserInput,
  CreateUserMutation,
  CreateUserMutationVariables,
  DeleteUserMutation,
  DeleteUserMutationVariables,
  GetUserQuery,
  GetUserQueryVariables,
  ListUsersQuery,
  ListUsersQueryVariables,
  UpdateUserInput,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  User,
} from "../API";

export async function fetchUserByID(variables: GetUserQueryVariables) {
  try {
    const response = await API.graphql<GraphQLQuery<GetUserQuery>>(
      graphqlOperation(queries.getUser, { ...variables })
    );
    console.debug("[USER] fetchUserByID SUCCESS", response.data?.getUser);
    return response.data?.getUser as User;
  } catch (error) {
    console.error("[USER] fetchUserByID ERROR", error);
    return null;
  }
}

export async function fetchList(variables: ListUsersQueryVariables) {
  try {
    const response = await API.graphql<GraphQLQuery<ListUsersQuery>>(
      graphqlOperation(queries.listUsers, {
        ...variables,
        filter: { _deleted: { ne: true } },
      } as ListUsersQueryVariables)
    );
    console.debug("[USER] fetchList", response.data?.listUsers?.items.length);
    return response.data?.listUsers?.items as User[];
  } catch (error) {
    console.error("[USER] fetchList", error);
    return [];
  }
}

export async function create(input: CreateUserInput) {
  try {
    const response = await API.graphql<GraphQLQuery<CreateUserMutation>>(
      graphqlOperation(mutations.createUser, {
        input: input,
      } as CreateUserMutationVariables)
    );
    console.debug("[USER] createUser", response.data?.createUser);
  } catch (error) {
    console.error("[USER] createUser", error);
    return null;
  }
}

export async function update(input: UpdateUserInput) {
  try {
    const response = await API.graphql<GraphQLQuery<UpdateUserMutation>>(
      graphqlOperation(mutations.updateUser, {
        input: input,
      } as UpdateUserMutationVariables)
    );
    console.debug("[USER] updateUser", response.data?.updateUser);
  } catch (error) {
    console.error("[USER] updateUser", error);
    return null;
  }
}

export async function deleteByID(id: string) {
  try {
    const response = await API.graphql<GraphQLQuery<DeleteUserMutation>>(
      graphqlOperation(mutations.deleteUser, {
        input: { id: id },
      } as DeleteUserMutationVariables)
    );
    console.debug("[USER] deleteUserByID", response.data?.deleteUser);
  } catch (error) {
    console.error("[USER] deleteUserByID", error);
    return null;
  }
}
