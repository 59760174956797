import { ItemType } from "antd/lib/menu/hooks/useItems";
import { Divider, Menu } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Image } from "@aws-amplify/ui-react";
import { Box, Typography } from "@mui/material";

import AdminInfo from "../admin-info";
import { useAppContext } from "../../context/app-context";
import awsconfig from "../../aws-exports";

const envStart = awsconfig.aws_user_files_s3_bucket.lastIndexOf("-");
const env = awsconfig.aws_user_files_s3_bucket.slice(envStart + 1);

export default function SideMenu() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const appContext = useAppContext();

  const mainMenuItems: ItemType[] = [
    { key: "/", label: t("app.side-menu.orders") },
    { key: "drivers", label: "Fahrer Übersicht" },
    { key: "restaurants", label: "Restaurants Übersicht" },
    { key: "invoices", label: "Abrechnungen" },
    { key: "settings", label: t("app.side-menu.settings") },
  ];

  const menuItems: ItemType[] = [
    ...(appContext.authUser ? mainMenuItems : []),
    // { key: "settings", label: t("app.side-menu.settings") },
    // { key: "signOut", label: t("app.side-menu.sign-out"), danger: true },
  ];

  const onClickMenuItem = async (menuItem: ItemType) => {
    navigate(`${menuItem?.key}`);
  };

  return (
    <>
      <Image
        alt="tasty-logo"
        src={require("../../assets/logo.png")}
        style={{ margin: 0, padding: 32 }}
      />

      <Divider />

      <AdminInfo />

      <Divider />

      <Menu
        items={menuItems}
        onClick={onClickMenuItem}
        style={{ alignItems: "flex-start" }}
      />

      {env !== "prod" && (
        <>
          <Divider />
          <Box color={"orange"}>
            <Typography textAlign={"center"} fontWeight={"bold"}>
              {env.toUpperCase()}
            </Typography>
          </Box>
        </>
      )}
    </>
  );
}
