import { useEffect, useState } from "react";

import { Card, List, message } from "antd";
import moment from "moment";

import { API, graphqlOperation, Storage } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { S3ProviderListOutputItem } from "@aws-amplify/storage";
import {
  Box,
  Button,
  ButtonGroup,
  ListItem,
  ListItemText,
  Modal,
  Typography,
} from "@mui/material";

import { listRestaurants } from "../../graphql/queries";
import { calculateRestaurantInvoice } from "../../graphql/mutations";
import {
  Restaurant,
  ListRestaurantsQuery,
  ListRestaurantsQueryVariables,
} from "../../API";

import InvoicePDF from "./invoice-pdf";
import { RestaurantWeeklyBill } from "../../models";
import { Loader } from "@aws-amplify/ui-react";
import { useAppContext } from "../../context/app-context";

async function queryRestaurants(variables: ListRestaurantsQueryVariables) {
  const payload = await API.graphql<GraphQLQuery<ListRestaurantsQuery>>(
    graphqlOperation(listRestaurants, variables ? variables : {})
  );
  return payload.data?.listRestaurants?.items.filter((r) => !r?._deleted);
}

export default function RestaurantInvoices() {
  const { appConfig } = useAppContext();

  const [visible, setVisible] = useState(false);
  const [downloadingKey, setDownloadingKey] = useState("");
  const [weeklyBill, setWeeklyBill] = useState<RestaurantWeeklyBill | null>(
    null
  );
  const [invoiceDateRange, setInvoiceDateRange] = useState<string | null>(null);
  const [selectedRestaurant, setSelectedRestaurant] =
    useState<Restaurant | null>(null);

  const [restaurants, setRestaurants] = useState<Restaurant[]>([]);
  const [doneLoadingRestaurants, setDoneLoadingRestaurants] = useState(false);
  const [weeks, setWeeks] = useState<string[]>([]);
  const [storageInvoices, setStorageInvoices] = useState<
    S3ProviderListOutputItem[]
  >([]);

  useEffect(() => {
    // DataStore.query(Restaurant).then(setRestaurants);
    setDoneLoadingRestaurants(false);
    queryRestaurants({})
      .then((data) => setRestaurants(data as Restaurant[]))
      .finally(() => setDoneLoadingRestaurants(true));
  }, []);

  useEffect(() => {
    if (!doneLoadingRestaurants) {
      return;
    }

    async function queryStoreageInvoices() {
      for await (const rest of restaurants) {
        await fetchStorageInvoices(rest.id);
      }
    }

    queryStoreageInvoices();
  }, [doneLoadingRestaurants]);

  const fetchStorageInvoices = async (restaurantID: string) => {
    setStorageInvoices([]);

    // setLoading(true);
    return Storage.list("invoices/" + restaurantID + "/", {
      level: "public",
      pageSize: "ALL",
    })
      .then(({ results, hasNextToken }) => {
        console.log("Storage", { results });

        setStorageInvoices((prev) => [...prev, ...results]);
      })
      .catch((err) => console.error("Storage error", err));
  };

  useEffect(() => {
    if (!storageInvoices) {
      return;
    }

    let startDate = moment("2023-10-02").startOf("week").format("YYYY-MM-DD");
    let endDate = moment(new Date()).startOf("week").format("YYYY-MM-DD");

    const weeks = [];
    while (startDate <= endDate) {
      weeks.push(startDate);
      startDate = moment(startDate).add(7, "days").format("YYYY-MM-DD");
    }

    setWeeks(weeks.reverse());

    console.log({ weeks });
  }, [storageInvoices]);

  async function onClickOpenPDF(
    _hasInvoice: S3ProviderListOutputItem,
    restaurant: Restaurant
  ) {
    console.log("onClickOpenPDF", _hasInvoice);
    if (!_hasInvoice.key) {
      return message.warning("PDF hat keine Rechnung");
    }

    console.log("trying to download: ", _hasInvoice.key);

    setDownloadingKey(_hasInvoice.key);

    const result = await Storage.get(_hasInvoice.key, {
      download: true,
      level: "public",
      contentType: "application/json",
      cacheControl: "no-cache",
      progressCallback(progress) {
        console.log(`Downloaded: ${progress.loaded}/${progress.total}`);
      },
    });

    // @ts-ignore
    const text = await result.Body.text();

    const weeklyInvoice = JSON.parse(text) as RestaurantWeeklyBill;

    const start = _hasInvoice.key
      ?.substring(_hasInvoice.key.lastIndexOf("/") + 1)
      .split("---")[0];
    const end = _hasInvoice.key
      ?.substring(_hasInvoice.key.lastIndexOf("/") + 1)
      .split("---")[1]
      .replace(".json", "");

    const dateRange =
      moment(start).format("DD.MM.YYYY") +
      " - " +
      moment(end).format("DD.MM.YYYY");

    console.log({ weeklyInvoice, dateRange });

    onSetupInvoiceModal(weeklyInvoice, dateRange, restaurant);

    setDownloadingKey("");
  }

  function openPDFInvoiceModal() {
    setVisible(true);
  }

  function closePDFInvoiceModal() {
    setVisible(false);
    setWeeklyBill(null);
    setInvoiceDateRange(null);
    setSelectedRestaurant(null);
  }

  function onSetupInvoiceModal(
    weeklyBill: RestaurantWeeklyBill,
    dateRange: string,
    restaurant: Restaurant
  ): void {
    console.log("onSetupInvoiceModal", weeklyBill);
    setWeeklyBill(weeklyBill);
    setInvoiceDateRange(dateRange);
    openPDFInvoiceModal();
    setSelectedRestaurant(restaurant);
  }

  const billingAddressToString = (restaurant: Restaurant | null) => {
    const billingAddress =
      restaurant?.billingDetails?.name +
      "," +
      restaurant?.billingDetails?.address?.line1 +
      "," +
      restaurant?.billingDetails?.address?.postal_code +
      " " +
      restaurant?.billingDetails?.address?.city;

    return billingAddress || restaurant?.address || "";
  };

  return (
    <Card title={"Restaurant Invoices"}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <List style={{}}>
          {restaurants.map((rest) => {
            return (
              <Box>
                <ListItem>
                  <Typography>{rest.name}</Typography>
                </ListItem>

                {weeks.map((week) => {
                  const hasInvoice = storageInvoices.find((invoice) => {
                    const start = moment(
                      invoice.key
                        ?.substring(invoice.key.lastIndexOf("/") + 1)
                        .split("---")[0]
                    ).format("YYYY-MM-DD");
                    console.log("start", start);
                    return start === week;
                  });

                  return (
                    <ListItem divider>
                      <ListItemText>
                        {moment(week).format("DD.MM.YYYY")} -{" "}
                        {moment(week).endOf("week").format("DD.MM.YYYY")}
                      </ListItemText>

                      <ButtonGroup size="small" style={{ marginLeft: 8 }}>
                        {rest?.id && (
                          <GenerateButton
                            week={week}
                            restaurantID={rest.id}
                            reloadInvoices={() => fetchStorageInvoices(rest.id)}
                          />
                        )}

                        {hasInvoice && (
                          <Button
                            variant="contained"
                            color="info"
                            disabled={hasInvoice?.key === downloadingKey}
                            onClick={() => onClickOpenPDF(hasInvoice, rest)}
                          >
                            PDF
                          </Button>
                        )}
                      </ButtonGroup>
                    </ListItem>
                  );
                })}
              </Box>
            );
          })}
        </List>
      </div>

      <Modal open={visible} onClose={closePDFInvoiceModal}>
        <Box sx={style}>
          <InvoicePDF
            weeklyBill={weeklyBill}
            invoiceMetadata={{
              dateRange: invoiceDateRange || "",
              invoiceNumber:
                selectedRestaurant?.id.substring(0, 4).toUpperCase() +
                "-" +
                invoiceDateRange?.split(" - ")[1].replaceAll(".", ""),
              ordersCount: (weeklyBill?.data?.length || 0).toString(),
              restaurantFee: appConfig?.restaurantFee!,
            }}
            restaurantMetadata={{
              name:
                selectedRestaurant?.billingDetails?.name ||
                selectedRestaurant?.name,
              address: billingAddressToString(selectedRestaurant).replaceAll(
                ",",
                "\n"
              ),
              customerID: selectedRestaurant?.id.substring(0, 4).toUpperCase(),
            }}
          />
        </Box>
      </Modal>
    </Card>
  );
}

function GenerateButton({
  week,
  restaurantID,
  reloadInvoices,
  disabled,
}: {
  week: string;
  restaurantID: string;
  reloadInvoices?: () => void;
  disabled?: boolean;
}) {
  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    const payload = {
      restaurantID: restaurantID,
      startDate: moment(week + "_02:00", "YYYY-MM-DD_hh:mm")
        .utc()
        .toISOString(),
      endDate: moment(week + "_01:59", "YYYY-MM-DD_hh:mm")
        .endOf("week")
        .utc()
        .toISOString(),
    };

    console.log("calculateRestaurantInvoice start", { payload });
    try {
      setLoading(true);
      const response = await API.graphql(
        graphqlOperation(calculateRestaurantInvoice, payload)
      );
      console.log("calculateRestaurantInvoice", { response });

      message.success(
        "Rechnung wird generiert und sollte innerhalb weniger sekunden sichtbar sein"
      );
    } catch (error: any) {
      console.error("calculateRestaurantInvoice", { error });
      message.error(error.message);
    } finally {
      setTimeout(() => {
        setLoading(false);
        reloadInvoices && reloadInvoices();
      }, 10000);
    }
  };
  return (
    <Button
      size="small"
      disabled={false} //disabled || loading}
      variant="contained"
      color="warning"
      onClick={onClick}
    >
      {loading ? <Loader /> : "Generieren"}
    </Button>
  );
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  //border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
