import { GraphQLQuery, GraphQLSubscription } from "@aws-amplify/api";
import { API, graphqlOperation } from "aws-amplify";
import { getRestaurant, listRestaurants } from "../graphql/queries";
import {
  GetRestaurantQuery,
  GetRestaurantQueryVariables,
  ListRestaurantsQuery,
  ListRestaurantsQueryVariables,
  OnUpdateRestaurantSubscription,
  OnUpdateRestaurantSubscriptionVariables,
  Restaurant,
  UpdateRestaurantInput,
  UpdateRestaurantMutation,
} from "../API";
import { updateRestaurant } from "../graphql/mutations";
import { onUpdateRestaurant } from "../graphql/subscriptions";
import removeTypename from "./utils/remove-typename";

export async function getRestaurantByID(
  variables: GetRestaurantQueryVariables
) {
  const { data } = await API.graphql<GraphQLQuery<GetRestaurantQuery>>(
    graphqlOperation(getRestaurant, variables)
  );

  return data?.getRestaurant as Restaurant;
}

export async function getRestaurants() {
  try {
    const response = await API.graphql<GraphQLQuery<ListRestaurantsQuery>>(
      graphqlOperation(listRestaurants, {
        filter: { _deleted: { ne: true } },
        limit: 100,
      } as ListRestaurantsQueryVariables)
    );
    console.debug(
      "[RESTAURANT] getRestaurants",
      response.data?.listRestaurants?.items.length
    );
    return response.data?.listRestaurants?.items as Restaurant[];
  } catch (error) {
    console.error("[RESTAURANT] getRestaurants", error);
    return [];
  }
}

export async function updateRestaurantByID(input: UpdateRestaurantInput) {
  const origin = await getRestaurantByID({ id: input.id });
  const inputWithVersion = {
    ...input,
    _version: origin?._version,
  };

  const payload = await API.graphql<GraphQLQuery<UpdateRestaurantMutation>>({
    query: updateRestaurant,
    variables: { input: removeTypename(inputWithVersion) },
  });
  return payload.data?.updateRestaurant as Restaurant;
}

export const observeRestaurantes = (
  variables: OnUpdateRestaurantSubscriptionVariables
) =>
  API.graphql<GraphQLSubscription<OnUpdateRestaurantSubscription>>({
    query: onUpdateRestaurant,
    variables,
  });
